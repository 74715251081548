const Fleet = require("./Fleet.js");
const Unit = require("./Unit.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const SpaceObject = require("./SpaceObject.js");
const Planet = require("./Planet.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const UIMessage = require("../Connection/UIMessage.js");
const Color = require("../../../Common/Config/Colors.js");
const LogMessage = require("../Connection/LogMessage.js");
const SecondaryObject = require("./SecondaryObject.js");
const Curiosity = require("./Curiosity.js");

class System {
  //Creation of objects

  static createFactionDraftSystem(x, y) {
    return {
      x: x,
      y: y,
      name: "draft system",
      isSystem: true,
      isFactionDraftSystem: true,
      factionsToPickFrom: [],
      hasBeenActivated: false,
      isActiveSystem: false,
      objects: [],
      fleets: [],
      incomingFleets: [],
      faction: null,
      hasBeenActivated: false,
      isActiveSystem: false,
      endRoundData: {
        spaceCombatBook: null,
        didSpaceCombatOccur: false,
        groundCombatBook: null,
        didGroundCombatOccur: false,
      },

      //Slice points
      slicePoints: 0,
    };
  }

  static createSystemCommorData(x, y, name, objects) {
    const data = {
      name: name,
      x: x,
      y: y,
      isSystem: true,
      objects: objects,
      fleets: [],
      incomingFleets: [],
      leavingFleets: [],
      faction: null,
      hasBeenActivated: false,
      isActiveSystem: false,
      endRoundData: {
        spaceCombatBook: null,
        didSpaceCombatOccur: false,
        groundCombatBook: null,
        didGroundCombatOccur: false,
      },
      spaceObjectDisplaySetup: CustomMath.generateRandomNumber(0, 1),
      activityLogs: [],
      combatLog: LogBook.createLogBook(),
      backgroundObject: this.createBackgroundObject(),
    };

    return data;
  }

  static createSystem(x, y, name, planetTypeDeck = null) {
    //console.log("fleet : ", fleet);

    const objects = this.createObjects(planetTypeDeck);

    /*let planetAmountRandom = CustomMath.generateRandomReal(0, 100);
    if ((planetAmountRandom -= 50) < 0) {
    } else if ((planetAmountRandom -= 20) < 0) {
      objects[0] = SpaceObject.createPlanet();
    } else if ((planetAmountRandom -= 20) < 0) {
      objects[1] = SpaceObject.createPlanet();
    } else if ((planetAmountRandom -= 15) < 0) {
      objects[0] = SpaceObject.createPlanet();
      objects[1] = SpaceObject.createPlanet();
    }*/

    const system = {
      ...this.createSystemCommorData(x, y, name, objects),
    };

    //LogAttachment.create(system, true, true);
    System.createNewRoundLogs(system, 1);
    System.generateLogActivity(
      system,
      "System " + system.name + " has been created by the universe"
    );

    this.renameObjectsInSystem(system);

    return system;
  }

  static renameObjectsInSystem(system) {
    for (let i = 0; i < system.objects.length; i++) {
      let letter = "";
      if (i === 0) letter = "A";
      if (i === 1) letter = "B";
      if (i === 2) letter = "C";
      if (i === 3) letter = "D";
      system.objects[i].name = system.name + " " + letter;
    }
  }

  static createHomeSystem(x, y, objectsConfig, factionName) {
    //console.log("Create Home System : objectsConfig : ", objectsConfig);
    const objects = [];
    for (let objectConfig of objectsConfig) {
      //console.log("objectConfig : ", objectConfig);
      if (objectConfig.isPlanet) {
        objects.push(SpaceObject.createPlanet(objectConfig.type));
        objects[objects.length - 1].faction = factionName;
      } else {
        if (objectConfig.type === "void")
          objects.push(SpaceObject.createVoid());
        else {
          throw new Error("Object type not found");
        }
      }
    }
    const name = null;
    const system = {
      ...this.createSystemCommorData(x, y, name, objects),

      faction: factionName,
      isHomeSystem: true,
    };

    System.createNewRoundLogs(system, 1);
    System.generateLogActivity(system, "Home system of " + factionName);

    return system;
  }

  static createObjects(planetTypeDeck = null) {
    // Create an array of four objects (e.g., planets, nebulae, wormhole, pirate base...)
    const objects = [];

    // Create and push your objects into the array
    // Example:
    objects.push(SecondaryObject.createVoid());
    objects.push(SecondaryObject.createVoid());

    //Replace objects by a planet
    let planetAmountRandom = CustomMath.generateRandomReal(0, 100);
    if ((planetAmountRandom -= 80) < 0) {
      //With planet
      let planetLocationRandom = CustomMath.generateRandomReal(0, 100);
      if ((planetLocationRandom -= 50) < 0) {
        objects[0] = SpaceObject.createPlanet(null, planetTypeDeck);
        objects[1] = SecondaryObject.createSecondaryObject();
      } else {
        objects[1] = SpaceObject.createPlanet(null, planetTypeDeck);
        objects[0] = SecondaryObject.createSecondaryObject();
      }
    } else {
      //Without planet
      objects[0] = SecondaryObject.createSecondaryObject();
      objects[1] = SecondaryObject.createSecondaryObject();
    }

    //console.log("object : ", objects);
    return objects;
  }

  static getSpaceObjects(system) {
    return system.objects;
  }

  static getSpaceObjectsFromType(system, type) {
    if (!system.objects) return [];
    return system.objects.filter((object) => object.type === type);
  }

  static getSpaceObjectFromName(system, name) {
    if (!system.objects) return null;
    return system.objects.find((object) => object.name === name);
  }

  static createObject() {
    const createPlanet = () => {
      const planetTypes = [
        {
          name: "terran",
          probability: 1,
          callback: () => {
            return "terran";
          },
        },
        {
          name: "gas giant",
          probability: 1,
          callback: () => {
            return "gas giant";
          },
        },
        {
          name: "desert",
          probability: 1,
          callback: () => {
            return "desert";
          },
        },
        {
          name: "ice",
          probability: 1,
          callback: () => {
            return "ice";
          },
        },
        {
          name: "volcanic",
          probability: 1,
          callback: () => {
            return "volcanic";
          },
        },
        {
          name: "oceanic",
          probability: 1,
          callback: () => {
            return "oceanic";
          },
        },
      ];

      const planetType = CustomMath.executeRandomEvent(planetTypes);
      let mineralBorneInf = 0;
      let mineralBorneSup = 0;
      let foodBorneInf = 0;
      let foodBorneSup = 0;
      let scienceBorneInf = 0;
      let scienceBorneSup = 0;
      let sizeBornInf = 0;
      let sizeBornSup = 0;

      switch (planetType) {
        case "terran":
          mineralBorneInf = 0.5;
          mineralBorneSup = 2;
          foodBorneInf = 1;
          foodBorneSup = 2.5;
          scienceBorneInf = 0.9;
          scienceBorneSup = 1.1;
          sizeBornInf = 8;
          sizeBornSup = 12;
          break;
        case "gas giant":
          mineralBorneInf = 1;
          mineralBorneSup = 2.2;
          foodBorneInf = 0;
          foodBorneSup = 0.6;
          scienceBorneInf = 1.2;
          scienceBorneSup = 1.6;
          sizeBornInf = 12;
          sizeBornSup = 16;
          break;
        case "desert":
          mineralBorneInf = 0.5;
          mineralBorneSup = 2;
          foodBorneInf = 0.5;
          foodBorneSup = 1;
          scienceBorneInf = 0.9;
          scienceBorneSup = 1.1;
          sizeBornInf = 7;
          sizeBornSup = 14;
          break;
        case "ice":
          mineralBorneInf = 0.5;
          mineralBorneSup = 2;
          foodBorneInf = 0.5;
          foodBorneSup = 1.5;
          scienceBorneInf = 0.9;
          scienceBorneSup = 1.1;
          sizeBornInf = 7;
          sizeBornSup = 14;
          break;
        case "volcanic":
          mineralBorneInf = 1.5;
          mineralBorneSup = 2.5;
          foodBorneInf = 0;
          foodBorneSup = 0.5;
          scienceBorneInf = 1;
          scienceBorneSup = 1.4;
          sizeBornInf = 6;
          sizeBornSup = 10;
          break;
        case "oceanic":
          mineralBorneInf = 0;
          mineralBorneSup = 1;
          foodBorneInf = 1.2;
          foodBorneSup = 2.5;
          scienceBorneInf = 0.9;
          scienceBorneSup = 1.1;
          sizeBornInf = 9;
          sizeBornSup = 15;
          break;
      }
      const mineral = Math.round(
        CustomMath.generateRandomReal(mineralBorneInf, mineralBorneSup) * 10
      );
      const food = Math.round(
        CustomMath.generateRandomReal(foodBorneInf, foodBorneSup) * 10
      );
      const science = Math.round(
        CustomMath.generateRandomReal(scienceBorneInf, scienceBorneSup) * 10
      );
      const size = Math.round(
        CustomMath.generateRandomReal(sizeBornInf, sizeBornSup)
      );

      return {
        class: "planet",
        type: planetType,
        mineral: mineral,
        food: food,
        science: science,
        size: size,
      };
    };
    const createWormhole = () => {
      const scienceBorneInf = 1.8;
      const scienceBorneSup = 2.5;
      const sizeBornInf = 3;
      const sizeBornSup = 6;
      const science =
        Math.round(
          CustomMath.generateRandomReal(scienceBorneInf, scienceBorneSup) * 10
        ) / 10;
      const size = Math.round(
        CustomMath.generateRandomReal(sizeBornInf, sizeBornSup)
      );

      return { class: "wormhole", science: science, size: size };
    };
    const createAnomaly = () => {
      const anomalyTypes = [
        {
          name: "gravitational anomaly",
          probability: 1,
          callback: () => {
            return "gravitational anomaly";
          },
        },
        {
          name: "temporal anomaly",
          probability: 1,
          callback: () => {
            return "temporal anomaly";
          },
        },
        {
          name: "energy vortex",
          probability: 1,
          callback: () => {
            return "energy vortex";
          },
        },
        {
          name: "dimensional rift",
          probability: 1,
          callback: () => {
            return "dimensional rift";
          },
        },
        {
          name: "cosmic strings",
          probability: 1,
          callback: () => {
            return "cosmic strings";
          },
        },
        {
          name: "hyperspace turbulence",
          probability: 1,
          callback: () => {
            return "hyperspace turbulence";
          },
        },
      ];

      const anomalyType = CustomMath.executeRandomEvent(anomalyTypes);
      let mineralBorneInf = 0;
      let mineralBorneSup = 0;
      let scienceBorneInf = 0;
      let scienceBorneSup = 0;
      let sizeBornInf = 0;
      let sizeBornSup = 0;

      switch (anomalyType) {
        case "gravitational anomaly":
          mineralBorneInf = 0;
          mineralBorneSup = 0;
          scienceBorneInf = 1.5;
          scienceBorneSup = 2.5;
          sizeBornInf = 4;
          sizeBornSup = 8;
          break;
        case "temporal anomaly":
          mineralBorneInf = 0;
          mineralBorneSup = 0;
          scienceBorneInf = 1.5;
          scienceBorneSup = 2.5;
          sizeBornInf = 4;
          sizeBornSup = 8;
          break;
        case "energy vortex":
          mineralBorneInf = 0;
          mineralBorneSup = 0;
          scienceBorneInf = 1.5;
          scienceBorneSup = 2.5;
          sizeBornInf = 4;
          sizeBornSup = 8;
          break;
        case "dimensional rift":
          mineralBorneInf = 0;
          mineralBorneSup = 0;
          scienceBorneInf = 1.5;
          scienceBorneSup = 2.5;
          sizeBornInf = 4;
          sizeBornSup = 8;
          break;
        case "cosmic strings":
          mineralBorneInf = 0;
          mineralBorneSup = 0;
          scienceBorneInf = 1.5;
          scienceBorneSup = 2.5;
          sizeBornInf = 4;
          sizeBornSup = 8;
          break;
        case "hyperspace turbulence":
          mineralBorneInf = 0;
          mineralBorneSup = 0;
          scienceBorneInf = 1.5;
          scienceBorneSup = 2.5;
          sizeBornInf = 4;
          sizeBornSup = 8;
      }
      const science =
        Math.round(
          CustomMath.generateRandomReal(scienceBorneInf, scienceBorneSup) * 100
        ) / 10;
      const size = Math.round(
        CustomMath.generateRandomReal(sizeBornInf, sizeBornSup)
      );

      return {
        class: "anomaly",
        type: anomalyType,
        science: science,
        size: size,
      };
    };

    const events = [
      { name: "planet", probability: 1, callback: createPlanet },
      //{ name: "whormhole", probability: 0.05, callback: createWormhole},
      //{ name: "anomaly", probability: 0.25, callback: createAnomaly},
    ];

    const object = CustomMath.executeRandomEvent(events);
    return object;
  }

  static getCoords(system) {
    return { x: system.x, y: system.y };
  }

  static setCoords(system, x, y) {
    system.x = x;
    system.y = y;
  }

  //Fleet
  static addFleetToSystem(system, fleet) {
    const fleetTo = System.getFleet(system, fleet.faction);
    if (fleetTo) {
      Fleet.addFleetToFleet(fleet, fleetTo);
    } else {
      system.fleets.push(fleet);
    }
  }

  static addIncomingFleetToSystem(system, fleet) {
    system.incomingFleets.push(fleet);
  }

  static cleanIncomingFleet(system) {
    system.incomingFleets = [];
  }

  static getIncomingFleet(system, faction) {
    return Fleet.getFleetFromFleets(system.incomingFleets, faction);
  }

  static getIncomingFleetOrCreate(playerData, system, faction) {
    return Fleet.getFleetOrCreateFromFleets(
      playerData,
      system.incomingFleets,
      faction
    );
  }

  static getFleetFromUnitId(system, unitId) {
    const fleet = System.getFleets(system);
    for (let i = 0; i < fleet.length; i++) {
      const units = Fleet.getUnits(fleet[i]);
      for (let j = 0; j < units.length; j++) {
        if (units[j].id === unitId) {
          return fleet[i];
        }
      }
    }
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const planetFleets = Planet.getFleets(planet);
      for (let j = 0; j < planetFleets.length; j++) {
        const units = Fleet.getUnits(planetFleets[j]);
        for (let k = 0; k < units.length; k++) {
          if (units[k].id === unitId) {
            return planetFleets[j];
          }
        }
      }
    }
    return null;
  }

  static mergeIncomingFleetsToSystem(playerData, system) {
    for (let i = 0; i < system.incomingFleets.length; i++) {
      const fleet = system.incomingFleets[i];
      const fleetTo = System.getFleetOrCreate(
        playerData,
        system,
        fleet.faction
      );
      Fleet.addFleetToFleet(fleet, fleetTo);
    }
    system.incomingFleets = [];
  }

  static removeFleetFromSystem(system, fleetToRemove) {
    const spaceFleet = System.getFleet(system, fleetToRemove.faction);
    if (spaceFleet) {
      Fleet.removeFleetFromFleet(fleetToRemove, spaceFleet);
    }
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planetFleet = Planet.getFleet(planets[i], fleetToRemove.faction);
      if (planetFleet) {
        Planet.removeFleetFromPlanet(planets[i], fleetToRemove);
      }
    }
  }

  //Get
  static getUnitFromId(system, unitId) {
    const fleets = System.getFleets(system);
    for (let i = 0; i < fleets.length; i++) {
      const unit = Fleet.getUnitFromId(fleets[i], unitId);
      if (unit) return unit;
    }

    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const unit = Planet.getUnitFromId(planets[i], unitId);
      if (unit) return unit;
    }

    return null;
  }

  static getFactionsInSystem(system) {
    const factions = [];
    const fleets = System.getFleets(system);
    for (let i = 0; i < fleets.length; i++) {
      factions.push(fleets[i].faction);
    }

    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const factionsOnPlanet = Planet.getFactionsOnPlanet(planets[i]);
      for (let j = 0; j < factionsOnPlanet.length; j++) {
        factions.push(factionsOnPlanet[j]);
      }
    }

    //remove duplicates and send
    return factions.filter((item, index) => factions.indexOf(item) === index);
  }

  static getFleetOrCreate(playerData, system, faction) {
    let fleet = System.getFleet(system, faction);
    if (!fleet) {
      fleet = Fleet.createFleet(playerData, faction);
      System.addFleetToSystem(system, fleet);
    }
    return fleet;
  }

  static getFleet(system, faction) {
    if (system.isDraftSystem) {
      return null;
    }

    //Get a fleet from a system
    let fleetIndex = system.fleets.findIndex(
      (fleet) => fleet.faction === faction
    );
    if (fleetIndex == -1) {
      return null;
    } else {
      if (Fleet.isEmpty(system.fleets[fleetIndex])) {
        // This check is needed because during the update of the global map, some system may have empty fleets
        system.fleets.splice(fleetIndex, 1);
        return null;
      }
      return system.fleets[fleetIndex];
    }
  }

  static getMinorFactionFleetsFromSystem(system) {
    const minorFactionFleets = [];
    const fleets = System.getFleets(system);
    for (let i = 0; i < fleets.length; i++) {
      if (fleets[i].faction.startsWith("(m)")) {
        minorFactionFleets.push(fleets[i]);
      }
    }
    return minorFactionFleets;
  }

  static getMinorFactionObjects(system) {
    const objects = [];
    for (let i = 0; i < system.objects.length; i++) {
      if (
        system.objects[i].type ===
        SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION
      ) {
        objects.push(system.objects[i]);
      }
    }
    return objects;
  }

  static getAllFleetsIncludingPlanets(system) {
    const fleets = [];
    fleets.push(...System.getFleets(system));
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planetFleet = Planet.getFleets(planets[i]);
      if (planetFleet) {
        fleets.push(...planetFleet);
      }
    }
    return fleets;
  }

  static getAllUnitsFromFactionIncludingPlanets(
    playerData,
    system,
    factionName
  ) {
    const units = [];
    const fleets = System.getAllFleetsIncludingPlanets(system);
    for (let i = 0; i < fleets.length; i++) {
      if (fleets[i].faction === factionName) {
        const fleetUnits = Fleet.getUnits(fleets[i]);
        units.push(...fleetUnits);
      }
    }
    return units;
  }

  static getUnitsFromTypeFromFaction(playerData, system, factionName, type) {
    const units = this.getAllUnitsFromFactionIncludingPlanets(
      playerData,
      system,
      factionName
    );
    return units.filter((unit) => unit.type === type);
  }

  static getPlanetFromName(system, planetName) {
    return system.objects.find((object) => object.name === planetName);
  }

  static removeFleetsExeptFaction(system, faction) {
    system.fleets = system.fleets.filter((fleet) => fleet.faction === faction);
  }

  static removeFleetFromFaction(system, faction) {
    system.fleets = system.fleets.filter((fleet) => fleet.faction !== faction);
  }

  static getFactionFromUnit(system, unit) {
    const fleets = System.getFleets(system);
    for (let i = 0; i < fleets.length; i++) {
      if (Fleet.isUnitInFleet(fleets[i], unit)) {
        return fleets[i].faction;
      }
    }

    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planetFleets = Planet.getFleets(planets[i]);
      for (let j = 0; j < planetFleets.length; j++) {
        if (Fleet.isUnitInFleet(planetFleets[j], unit)) {
          return planetFleets[j].faction;
        }
      }
    }
    return null;
  }

  static getControllingFleet(system) {
    //Get the fleet controlling a system
    if (!system.faction) return null;

    let fleetIndex = system.fleets.findIndex(
      (fleet) => fleet.faction === system.faction
    );
    if (fleetIndex == -1) {
      return null;
    } else {
      return system.fleets[fleetIndex];
    }
  }

  static isUnitInSpaceArea(playerData, system, unit) {
    const fleet = System.getFleet(system, playerData.faction.name);
    if (!fleet) return false;
    return Fleet.isUnitInFleet(fleet, unit);
  }

  static getFleets(system) {
    //ATTENTION : appelée par le serveur lors du resolve new round, il est possible que certaines fleet soient vides, car elles seraient updatée sur la global map et non retirée du système dans les playerData.
    return system.fleets;
  }

  static hideNotDiscoveredAtStart(system) {
    //Hide objects that should not been discovered at start of the game
    for (let object in system.objects) {
      if (!object.isDiscoveredAtStart) {
        object = SpaceObject.createNotDiscoveredObject();
      }
    }
  }

  static areSystemsTheSame(systemA, systemB) {
    if (
      systemA.name === systemB.name &&
      systemA.x === systemB.x &&
      systemA.y === systemB.y
    ) {
      return true;
    } else {
      return false;
    }
  }

  static activateSystem(system) {
    if (system.hasBeenActivated) {
      throw new Error("System " + system.name + " has already been activated");
    }
    system.isActiveSystem = true;
    system.hasBeenActivated = true;
  }

  static deactivateSystem(system) {
    system.isActiveSystem = false;
    system.hasBeenActivated = true;
  }

  static isActiveSystem(system) {
    return system.isActiveSystem;
  }

  static getPlanets(system) {
    if (!system.objects) return [];
    return system.objects.filter((object) => object.isPlanet);
  }

  static getPlanetsFromFaction(system, faction) {
    const planets = this.getPlanets(system);
    return planets.filter((planet) => planet.faction === faction);
  }

  static getPlanetsNotFromFaction(system, faction) {
    const planets = this.getPlanets(system);
    return planets.filter((planet) => planet.faction !== faction);
  }

  static getPlanetsWhereFactionHasSomething(system, factionName) {
    const planetsList = [];
    const planets = System.getPlanets(system);
    for (let j = 0; j < planets.length; j++) {
      const planet = planets[j];
      if (
        planet.faction === factionName ||
        !Fleet.isEmpty(Planet.getFleet(planet, factionName))
      ) {
        planetsList.push(planet);
      }
    }

    return planetsList;
  }

  static clearSystem(system) {
    system.isActiveSystem = false;
    system.hasBeenActivated = false;
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      Planet.clearPlanet(planets[i]);
    }
  }

  static clearGlobalMapSystem(system) {
    system.fleets = [];

    system.activityLogs = [LogBook.createLogBook()];
    system.combatLog = LogBook.createLogBook();

    const spaceObjects = System.getSpaceObjects(system);
    for (let i = 0; i < spaceObjects.length; i++) {
      SpaceObject.clearGlobalMapSpaceObject(spaceObjects[i]);
    }
  }

  static checkLogisticMass(playerData, system) {
    const fleet = System.getFleet(system, playerData.faction.name);
    if (!fleet) return;
    const fleetLogisticMass = Fleet.getLogisticMass(fleet);
    if (fleetLogisticMass > playerData.cargo) {
      throw new Error(
        "The total mass of your not carried ships is " +
          fleetLogisticMass +
          ". Your fleet limit is " +
          playerData.cargo +
          ". You can't have an amount of not carried ships in a single system having a combined total mass higher than your fleet limit."
      );
    }
  }

  static checkCapacity(playerData, system) {
    const fleet = System.getFleet(system, playerData.faction.name);
    if (!fleet) return;
    const fleetCapacity = Fleet.getTotalCapacity(playerData, fleet);
    const fleetRequiredCapacity = Fleet.getTotalRequiredCapacity(
      playerData,
      fleet
    );
    if (fleetRequiredCapacity > fleetCapacity) {
      throw new Error(
        "The total required capacity of your fleet is " +
          fleetRequiredCapacity +
          ". Your fleet capacity is " +
          fleetCapacity +
          ". You can't have a total required capacity higher than your fleet capacity."
      );
    }
  }

  static doesIncludeObject(system, object) {
    //Search by object.name
    return system.objects.some((obj) => obj.name === object.name);
  }

  static replaceObject(system, objectNameToReplace, objectReplacing) {
    const index = system.objects.findIndex(
      (object) => object.name === objectNameToReplace
    );
    if (index === -1) {
      throw new Error("Object " + objectNameToReplace + " not found in system");
    }
    system.objects[index] = objectReplacing;
  }

  static unMergeFromGlobalToPlayer(globalSystem, playerSystem, playerData) {
    /*if (playerSystem.name === "Fervent") {
      console.log("unMergeFromGlobalToPlayer : ");
      console.log(LogAttachment.getLogActivity(globalSystem));
    }*/
    /*LogAttachment.clearLogs(playerSystem);
    LogAttachment.setpreviousLog(
      playerSystem,
      LogAttachment.getLogActivity(globalSystem),
      LogAttachment.LOG_ACTIVITY
    );
    LogAttachment.setpreviousLog(
      playerSystem,
      LogAttachment.getLogCombat(globalSystem),
      LogAttachment.LOG_COMBAT
    );*/

    //System.clearLogsNewRound(playerSystem);

    System.replaceLastActivityLogBook(
      playerSystem,
      System.getLastActivityLogBook(globalSystem)
    );

    playerSystem.combatKey = globalSystem.combatKey;
    playerSystem.fleets = globalSystem.fleets;
    playerSystem.faction = globalSystem.faction;
    playerSystem.endRoundData = globalSystem.endRoundData;

    const globalPlanets = System.getPlanets(globalSystem);
    for (let i = 0; i < globalPlanets.length; i++) {
      const playerPlanet = System.getSpaceObjectFromName(
        playerSystem,
        globalPlanets[i].name
      );
      Planet.unMergeFromGlobalToPlayer(
        globalPlanets[i],
        playerPlanet,
        playerData
      );
    }

    //unmerge of the minor factions objects

    const objects = System.getSpaceObjects(globalSystem);
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION) {
        playerSystem.objects[i] = objects[i];
      }
    }
  }

  static mergeFromPlayerToGLobal(globalSystem, playerSystem, playerData) {
    const fleet = System.getFleet(playerSystem, playerData.faction.name);
    if (fleet) {
      System.addFleetToSystem(globalSystem, fleet);
    }

    //Add minor faction units, as they can't disapear, during a round, we can just add them and trust the id of the units to avoid dupplicates.
    const minorFleets = System.getMinorFactionFleetsFromSystem(playerSystem);
    for (let i = 0; i < minorFleets.length; i++) {
      System.addFleetToSystem(globalSystem, minorFleets[i]);
    }

    const globalActivityLog = System.getLastActivityLogBook(globalSystem);
    const playerLastActivityLog = System.getLastActivityLogBook(playerSystem);
    //console.log("debug : playerLastActivityLog : ", playerLastActivityLog);
    //const globalCombatLog = LogAttachment.getLogCombat(globalSystem);
    //const playerCombatLog = LogAttachment.getLogCombat(playerSystem);

    LogBook.concat(playerLastActivityLog, globalActivityLog);
    playerSystem.combatLog = LogBook.createLogBook();
    //LogBook.concat(playerCombatLog, globalCombatLog);

    const globalPlanets = System.getPlanets(globalSystem);
    for (let i = 0; i < globalPlanets.length; i++) {
      const playerPlanet = System.getSpaceObjectFromName(
        playerSystem,
        globalPlanets[i].name
      );
      Planet.mergeFromPlayerToGLobal(
        globalPlanets[i],
        playerPlanet,
        playerData
      );
    }

    //merge of the minor factions objects
    if (
      playerSystem.faction !== null &&
      playerSystem.faction === playerData.faction.name
    ) {
      const objects = System.getSpaceObjects(playerSystem);
      for (let i = 0; i < objects.length; i++) {
        if (
          objects[i].type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION
        ) {
          globalSystem.objects[i] = objects[i];
        }
      }
    }
  }

  //Logs
  static getActivityLog(system) {
    const logBook = LogBook.createLogBook();
    for (let i = 0; i < system.activityLogs.length; i++) {
      LogBook.concat(system.activityLogs[i], logBook);
    }
    LogBook.correctCursor(logBook);
    return logBook;
  }

  static getAllCombatKeys(system) {
    const data = [];
    if (system.endRoundData.didSpaceCombatOccur) {
      data.push({
        key: system.combatKey,
        object: system,
        text: "Space combat in " + system.name,
      });
    }
    const planets = System.getPlanets(system);
    planets.forEach((planet) => {
      if (planet.endRoundData.didGroundCombatOccur) {
        data.push({
          key: planet.combatKey,
          object: planet,
          text: "Ground combat on " + planet.name,
        });
      }
    });

    return data;
  }

  static getLastActivityLogBook(system) {
    LogBook.correctCursor(system.activityLogs[system.activityLogs.length - 1]);
    return system.activityLogs[system.activityLogs.length - 1];
  }

  static replaceLastActivityLogBook(system, logBook) {
    system.activityLogs[system.activityLogs.length - 1] = logBook;
  }

  static getCombatLog(system) {
    return system.combatLog;
  }

  static clearLogsNewRound(system) {
    system.combatLog = LogBook.createLogBook();
  }

  static createNewRoundLogs(system, roundNumber) {
    const logBook = LogBook.createLogBook();
    LogBook.createMessage(
      logBook,
      [{ content: "ROUND " + roundNumber }],
      false,
      LogMessage.TYPE_TITLE_SECTION
    );
    system.activityLogs.push(logBook);
    system.activityLogs.push(LogBook.createLogBook());
  }

  static generateLogActivity(system, text, itemArray = []) {
    const logBook = this.getLastActivityLogBook(system);
    LogBook.correctCursor(logBook);
    LogBook.generateAddMessage(logBook, text, itemArray);
  }

  /*static logActivityToSpaceObject(system, spaceObject, logMessage) {
    LogAttachment.logActivity(system, logMessage);
    LogAttachment.logActivity(spaceObject, logMessage);
  }


  static getLogItem(system) {
    return JSON.parse(JSON.stringify(system));
  }

  static clearLogs(system) {
    LogAttachment.clearLogs(system);
    for (let object of system.objects) {
      LogAttachment.clearLogs(object);
    }
  }*/

  //Control
  static canTransferThrough(playerData, system) {
    if (system.faction !== null && system.faction !== playerData.faction.name) {
      throw new Error(
        "You can't transfer through the system " +
          system.name +
          " because it is controlled by another faction"
      );
    }
  }

  static canTransferFromToPlanet(playerData, system, planet) {
    this.canTransferThrough(playerData, system);
  }

  //Admin

  static adminAfterRound(playerData, system, generateLog) {
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      Planet.adminAfterRound(playerData, planets[i]);
    }
    this.createNewRoundLogs(system, playerData.roundNumber);

    //Gain curiosity bonuses, background object bonuses
    if (system.backgroundObject !== System.BACK_NONE) {
      if (system.faction && system.faction === playerData.faction.name) {
        if (system.backgroundObject === Curiosity.object.mineralField.name) {
          playerData.mineral = CustomMath.roundDec(playerData.mineral + 1);
          generateLog(
            "Controling mineral field in " + system.name + " : gain 1 mineral"
          );
        }
        if (system.backgroundObject === Curiosity.object.fluxAnomaly.name) {
          playerData.science = CustomMath.roundDec(playerData.science + 1);
          generateLog(
            "Controling flux anomaly in " + system.name + " : gain 1 science"
          );
        }
        if (system.backgroundObject === Curiosity.object.uteriumDust.name) {
          playerData.credit = CustomMath.roundDec(playerData.credit + 1);
          generateLog(
            "Controling uterium dust in " + system.name + " : gain 1 credit"
          );
        }
      }
    }
  }

  static repair(playerData, system) {
    let repairOccured = false;

    //Repair in space
    const logBook = LogAttachment.getLogActivity(system);
    LogBook.createMessage(logBook, [{ content: "Repair in space" }], true);
    LogBook.addDown(logBook);

    //If player control the space area
    if (system.faction === playerData.faction.name) {
      if (
        //If player has a planet in the system
        System.getPlanetsFromFaction(system, playerData.faction.name).length > 0
      ) {
        const fleet = System.getFleet(system, playerData.faction.name);
        const units = Fleet.getUnits(fleet);
        for (let i = 0; i < units.length; i++) {
          if (
            Unit.getHP(playerData, units[i]) <
            Unit.getMaxHP(playerData, units[i])
          ) {
            Unit.repair(playerData, units[i], 1, logBook);
            repairOccured = true;
          }
        }
      }
    }
    if (repairOccured) {
      UIMessage.sendInfoMessageToClient(
        playerData,
        "Repair in space",
        "Your planets repair your ships : as you control this space area and at least a planet in this system, all of your ships have been repaired by 1 hp."
      );
      LogBook.addUp(logBook);
    } else {
      LogBook.addUp(logBook);
      LogBook.removeLast(logBook);
    }

    //Repair on planets
    repairOccured = false;

    const planets = System.getPlanetsFromFaction(
      system,
      playerData.faction.name
    );
    for (let k = 0; k < planets.length; k++) {
      const planet = planets[k];

      const logBookPlanet = LogAttachment.getLogActivity(planet);
      LogBook.createMessage(
        logBookPlanet,
        [{ content: "Repair on planet" }],
        true
      );
      LogBook.addDown(logBookPlanet);

      if (planet.faction === playerData.faction.name) {
        const fleet = Planet.getFleet(planet, playerData.faction.name);
        const units = Fleet.getUnits(fleet);
        for (let i = 0; i < units.length; i++) {
          if (Unit.getHP(units[i]) < Unit.getMaxHP(playerData, units[i])) {
            Unit.repair(playerData, units[i], 1, logBookPlanet);
            repairOccured = true;
          }
        }
      }

      if (repairOccured) {
        UIMessage.sendInfoMessageToClient(
          playerData,
          "Repair on planets",
          "All of your ground forces on planets you control in this system have been repaired by 1 hp."
        );
        LogBook.addUp(logBookPlanet);
      } else {
        LogBook.removeLast(logBookPlanet);
      }
    }
  }

  //Units
  static addUnit(playerData, system, unit, faction) {
    const fleet = System.getFleetOrCreate(playerData, system, faction);
    Fleet.addUnit(fleet, unit);
  }

  static removeUnits(playerData, system, units) {
    for (let i = 0; i < units.length; i++) {
      System.removeUnit(playerData, system, units[i]);
    }
  }

  static removeUnit(playerData, system, unit) {
    const fleets = System.getFleets(system);
    for (let i = 0; i < fleets.length; i++) {
      const fleet = fleets[i];
      if (Fleet.removeUnit(fleet, unit)) {
        return true;
      }
    }
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      if (Planet.removeUnit(playerData, planets[i], unit)) {
        return true;
      }
    }
    return false;
  }

  static isUnitInSystem(system, unit) {
    const fleets = System.getFleets(system);
    for (let i = 0; i < fleets.length; i++) {
      const fleet = fleets[i];
      if (Fleet.isUnitInFleet(fleet, unit)) {
        return true;
      }
    }
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      if (Planet.isUnitOnPlanet(planets[i], unit)) {
        return true;
      }
    }
    return false;
  }

  static getPlanetFromUnit(system, unit) {
    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      if (Planet.isUnitOnPlanet(planets[i], unit)) {
        return planets[i];
      }
    }
    return null;
  }

  //Ability

  /*static executeUnitAbility(playerData, system, abilityId, callback) {
    const systemFleet = System.getFleet(system, playerData.faction.name);
    Fleet.executeUnitAbility(playerData, systemFleet, abilityId, callback);
  }*/

  static getUnitWithAbilityFromFaction(playerData, system, faction, abilityId) {
    const systemFleet = System.getFleet(system, faction);
    const unitAb = Fleet.getUnitsWithAbility(
      playerData,
      systemFleet,
      abilityId
    );

    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planetFleet = Planet.getFleet(planets[i], faction);
      if (planetFleet) {
        const unitAbPlanet = Fleet.getUnitsWithAbility(
          playerData,
          planetFleet,
          abilityId
        );
        unitAb.push(...unitAbPlanet);
      }
    }

    return unitAb;
  }

  //Objects
  static isObjectInSystem(system, object) {
    return system.objects.some((obj) => obj.name === object.name);
  }

  //Map //Click //Display
  static getItemClicked(
    system,
    xPercent,
    yPercent,
    returnOnlyTheSystem = "all"
  ) {
    /*if (returnOnlyTheSystem === null) {
      return system;
    }*/

    const centerObject1X = system.spaceObjectDisplaySetup === 0 ? 0.25 : 0.75;
    const centerObject1Y = 0.25;
    const centerObject2X = system.spaceObjectDisplaySetup === 0 ? 0.75 : 0.25;
    const centerObject2Y = 0.75;
    //console.log("xPercent : ", xPercent);
    //console.log("yPercent : ", yPercent);

    let object = null;
    let activeDistance = null;
    //Distance from object 1
    let distance1 = CustomMath.getDistance(
      xPercent,
      yPercent,
      centerObject1X,
      centerObject1Y
    );
    if (distance1 < 0.25) {
      object = system.objects[0];
      activeDistance = distance1;
    }

    //Distance from object 2
    let distance2 = CustomMath.getDistance(
      xPercent,
      yPercent,
      centerObject2X,
      centerObject2Y
    );
    if (distance2 < 0.25) {
      object = system.objects[1];
      activeDistance = distance2;
    }

    if (object) {
      if (object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION)
        if (activeDistance < 0.15) {
          if (
            returnOnlyTheSystem === "all" ||
            returnOnlyTheSystem === "exiled"
          ) {
            return object;
          }
        }

      if (object.isPlanet)
        if (activeDistance < 0.25) {
          if (
            returnOnlyTheSystem === "all" ||
            returnOnlyTheSystem === "planet"
          ) {
            return object;
          }
        }
    }

    return system;
  }

  //Fond
  //Background
  //Object

  //static BACK_MINERAL_FIELD = "mineralField";
  //static BACK_ASTEROID_FIELD = "asteroidField";
  //static BACK_UTORIUM_DUST = "uteriumDust";
  //static BACK_STORM = "storm";
  //static BACK_CRYSTAL_FIELD = "crystalField";
  //static BACK_FLUX_ANOMALY = "fluxAnomaly";
  //static BACK_NEBULAE = "nebula";
  static BACK_NONE = "none";

  static createBackgroundObject() {
    const backgroundType = CustomMath.executeRandomEvent([
      { name: Curiosity.object.mineralField.name, probability: 2 },
      { name: Curiosity.object.asteroidField.name, probability: 1 },
      { name: Curiosity.object.uteriumDust.name, probability: 2 },
      { name: Curiosity.object.storm.name, probability: 1 },
      //{ name: System.BACK_CRYSTAL_FIELD, probability: 1 },
      { name: Curiosity.object.fluxAnomaly.name, probability: 2 },
      { name: Curiosity.object.nebula.name, probability: 1 },
      { name: this.BACK_NONE, probability: 2 },
    ]);

    const backgroundObject = {
      class: "background",
      type: backgroundType,
    };

    return backgroundObject.type;
  }
}

module.exports = System;

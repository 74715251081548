const CustomMath = require("../../../Common/Math/CustomMath");
const ImagesUrl = require("../../Other/ImagesUrl");
const Objectif = require("./Objectif_old");

class Objectives {
  //Selected Objectives
  static getSelectedObjectives = () => {
    return [
      this.NAME_SPEND_SCIENCE_1,
      this.NAME_SPEND_MINERAL_1,
      this.NAME_SPEND_ENERGY_1,
      this.NAME_SPEND_CREDIT_1,
      this.NAME_SPEND_POPULATION_1,
      //5
      this.NAME_PLANET_ICE_WATER_1,
      this.NAME_PLANET_EARTH_DESERT_1,
      this.NAME_PLANET_GAZ_VOLCANIC_1,
      //8
      this.NAME_EMPTY_SPACE_1,
      this.NAME_WHORMHOLE_1,
      this.NAME_MINOR_FACTION_1,
      //11
      this.NAME_SPACE_MIDDLE_1,
      this.NAME_PLANET_MIDDLE_1,
      this.NAME_SPACE_FAR_1,
      this.NAME_PLANET_FAR_1,
      //15
      this.NAME_SPACE_HS_1,
      this.NAME_PLANET_HS_1,
      //17
      this.NAME_TECH_RED_1,
      this.NAME_TECH_BLUE_1,
      this.NAME_TECH_GREEN_1,
      this.NAME_TECH_YELLOW_1,
      this.NAME_TECH_DIVERSITY_1,
      //22
      this.NAME_STRUCTURES_CANON_1,
      this.NAME_STRUCTURES_FACTORY_1,
      this.NAME_STRUCTURES_AO_1,
      this.NAME_STRUCTURES_PLANETARY_SHIELS_1,
      //26
      this.NAME_ARTIFACT_1,
      this.NAME_RELIC_1,
      //28
      this.NAME_DESTROYER_1,
      this.NAME_CRUISER_1,
      this.NAME_DREADNOUGHT_1,
      this.NAME_CARRIER_1,
      this.NAME_MECH_1,
      this.NAME_TECH_SPECIALITY,
      //33
    ];
  };

  //Spend objectives
  static NAME_SPEND_SCIENCE_1 = "Spend Science";
  static NAME_SPEND_MINERAL_1 = "Spend Mineral";
  static NAME_SPEND_ENERGY_1 = "Spend Energy";
  static NAME_SPEND_CREDIT_1 = "Spend Credit";
  static NAME_SPEND_POPULATION_1 = "Spend Population";

  //Control planets objectives
  static NAME_PLANET_ICE_WATER_1 = "Ice Water";
  static NAME_PLANET_EARTH_DESERT_1 = "Earth Desert";
  static NAME_PLANET_GAZ_VOLCANIC_1 = "Gaz Volcanic";
  static NAME_PLANET_LESS_2_MINERAL_1 = "Planet Less 2 Mineral";
  static NAME_PLANET_LESS_2_ENERGY_1 = "Planet Less 2 Energy";
  static NAME_PLANET_LESS_2_SCIENCE_1 = "Planet Less 2 Science";
  static NAME_PLANET_LESS_2_POPULATION_1 = "Planet Less 2 Population";

  //Space Area control objectives
  static NAME_EMPTY_SPACE_1 = "Empty Space";
  static NAME_WHORMHOLE_1 = "Whormhole";
  static NAME_MINOR_FACTION_1 = "Exiled";

  //Control in the middle
  static NAME_SPACE_MIDDLE_1 = "Middle Space";
  static NAME_PLANET_MIDDLE_1 = "Middle Planets";

  //Control far away
  static NAME_SPACE_FAR_1 = "Far Away Space";
  static NAME_PLANET_FAR_1 = "Far Away Planets";

  //Control in HS
  static NAME_SPACE_HS_1 = "HomeSystem Space";
  static NAME_PLANET_HS_1 = "HomeSystem Planets";

  //Techs
  static NAME_TECH_RED_1 = "Tech Red";
  static NAME_TECH_BLUE_1 = "Tech Blue";
  static NAME_TECH_GREEN_1 = "Tech Green";
  static NAME_TECH_YELLOW_1 = "Tech Yellow";
  static NAME_TECH_SPECIALITY = "Tech Speciality";
  static NAME_TECH_DIVERSITY_1 = "Tech Diversity";

  //Structures
  static NAME_STRUCTURES_CANON_1 = "Planetary Canons";
  static NAME_STRUCTURES_FACTORY_1 = "Factories";
  static NAME_STRUCTURES_AO_1 = "Assembly Orbitals";
  static NAME_STRUCTURES_PLANETARY_SHIELS_1 = "Planetary Shields";

  //Planet Bonus
  static NAME_PLANET_BONUS_MINERAL_1 = "Planet Bonus Mineral 1";
  static NAME_PLANET_BONUS_CREDIT_1 = "Planet Bonus Credit 1";
  static NAME_PLANET_BONUS_SCIENCE_1 = "Planet Bonus Science 1";
  static NAME_PLANET_BONUS_ENERGY_1 = "Planet Bonus Energy 1";
  static NAME_PLANET_BONUS_POPULATION_1 = "Planet Bonus Population 1";

  //Relics and artifacts
  static NAME_ARTIFACT_1 = "Artifacts";
  static NAME_RELIC_1 = "Relics";

  //Units
  static NAME_DESTROYER_1 = "Destroyers";
  static NAME_CRUISER_1 = "Cruisers";
  static NAME_DREADNOUGHT_1 = "Dreadnoughts";
  static NAME_CARRIER_1 = "Carriers";
  static NAME_MECH_1 = "Mechs";

  static create(name, missionDone = false) {
    return {
      name: name,
      missionDone: missionDone,
    };
  }

  static createDesc(objectiveName) {
    const desc = this.getObjectivesDesc()[objectiveName];
    //desc.missionDone = objective.missionDone;

    return desc;
  }

  static getObjectivesDesc = () => {
    const objectives = {};

    objectives[Objectives.NAME_SPEND_SCIENCE_1] = {
      name: Objectives.NAME_SPEND_SCIENCE_1,
      vp: 4,
      description: "Spend 5 sciences.",
    };

    objectives[Objectives.NAME_SPEND_MINERAL_1] = {
      name: Objectives.NAME_SPEND_MINERAL_1,
      vp: 4,
      description: "Spend 6 minerals.",
    };

    objectives[Objectives.NAME_SPEND_ENERGY_1] = {
      name: Objectives.NAME_SPEND_ENERGY_1,
      vp: 4,
      description: "Spend 5 energies.",
    };

    objectives[Objectives.NAME_SPEND_CREDIT_1] = {
      name: Objectives.NAME_SPEND_CREDIT_1,
      vp: 4,
      description: "Spend 5 credits.",
    };

    objectives[Objectives.NAME_SPEND_POPULATION_1] = {
      name: Objectives.NAME_SPEND_POPULATION_1,
      vp: 4,
      description: "Spend 5 population.",
    };

    objectives[Objectives.NAME_PLANET_LESS_2_ENERGY_1] = {
      name: Objectives.NAME_PLANET_LESS_2_ENERGY_1,
      vp: 4,
      description: "Control 3 planets which produce less than 2 energy each.",
    };

    objectives[Objectives.NAME_PLANET_ICE_WATER_1] = {
      name: Objectives.NAME_PLANET_ICE_WATER_1,
      vp: 5,
      description: "Control 3 planets with the type ice or oceanic.",
    };

    objectives[Objectives.NAME_PLANET_EARTH_DESERT_1] = {
      name: Objectives.NAME_PLANET_EARTH_DESERT_1,
      vp: 5,
      description: "Control 3 planets with the type earth or desert.",
    };

    objectives[Objectives.NAME_PLANET_GAZ_VOLCANIC_1] = {
      name: Objectives.NAME_PLANET_GAZ_VOLCANIC_1,
      vp: 5,
      description: "Control 3 planets with the type gaz or volcanic.",
    };

    objectives[Objectives.NAME_PLANET_LESS_2_MINERAL_1] = {
      name: Objectives.NAME_PLANET_LESS_2_MINERAL_1,
      vp: 4,
      description: "Control 3 planets which produce less than 2 minerals each.",
    };

    objectives[Objectives.NAME_PLANET_LESS_2_SCIENCE_1] = {
      name: Objectives.NAME_PLANET_LESS_2_SCIENCE_1,
      vp: 4,
      description: "Control 3 planets which produce less than 2 sciences each.",
    };

    objectives[Objectives.NAME_PLANET_LESS_2_POPULATION_1] = {
      name: Objectives.NAME_PLANET_LESS_2_POPULATION_1,
      vp: 4,
      description:
        "Control 3 planets which produce less than 2 population each.",
    };

    objectives[Objectives.NAME_EMPTY_SPACE_1] = {
      name: Objectives.NAME_EMPTY_SPACE_1,
      vp: 4,
      description: "Control the space area of 2 systems with no planets.",
    };

    objectives[Objectives.NAME_WHORMHOLE_1] = {
      name: Objectives.NAME_WHORMHOLE_1,
      vp: 4,
      description: "Control the space area of 2 systems with a whormhole.",
    };

    objectives[Objectives.NAME_MINOR_FACTION_1] = {
      name: Objectives.NAME_MINOR_FACTION_1,
      vp: 6,
      description:
        "Control the space area of systems which combined an amount of 8 exiled or more.",
    };

    objectives[Objectives.NAME_SPACE_MIDDLE_1] = {
      name: Objectives.NAME_SPACE_MIDDLE_1,
      vp: 5,
      description:
        "Control the space area of 4 systems which have a distant from your home system of at least 2.",
    };

    objectives[Objectives.NAME_PLANET_MIDDLE_1] = {
      name: Objectives.NAME_PLANET_MIDDLE_1,
      vp: 5,
      description:
        "Control 3 planets in systems which have a distant from your home system of at least 2.",
    };

    objectives[Objectives.NAME_SPACE_FAR_1] = {
      name: Objectives.NAME_SPACE_FAR_1,
      vp: 7,
      description:
        "Control the space area of 3 systems which have a distant from your home system of at least 3.",
    };

    objectives[Objectives.NAME_PLANET_FAR_1] = {
      name: Objectives.NAME_PLANET_FAR_1,
      vp: 7,
      description:
        "Control 2 planets in systems which have a distant from your home system of at least 3.",
    };

    objectives[Objectives.NAME_SPACE_HS_1] = {
      name: Objectives.NAME_SPACE_HS_1,
      vp: 7,
      description: "Control the space area of 1 other player's home system.  ",
    };

    objectives[Objectives.NAME_PLANET_HS_1] = {
      name: Objectives.NAME_PLANET_HS_1,
      vp: 8,
      description: "Control 1 planet in another player's home system.",
    };
    /*objectives[Objectives.NAME_PLANET_BONUS_MINERAL_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_MINERAL_1,
      vp: 4,
      description:  "Control 2 planets with a mineral bonus."
    };
    objectives[Objectives.NAME_PLANET_BONUS_CREDIT_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_CREDIT_1,
      vp: 4,
      description: "Control 2 planets with a credit bonus."
    };

    objectives[Objectives.NAME_PLANET_BONUS_SCIENCE_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_SCIENCE_1,
      vp: 4,
      description:       "Control 2 planets with a science bonus."
    };

    objectives[Objectives.NAME_PLANET_BONUS_ENERGY_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_ENERGY_1,
      vp: 4,
      description: "Control 2 planets with an energy bonus."
    };

    objectives[Objectives.NAME_PLANET_BONUS_POPULATION_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_POPULATION_1,
      vp: 4,
      description: "Control 2 planets with a population bonus."
    };*/
    objectives[Objectives.NAME_TECH_SPECIALITY] = {
      name: Objectives.NAME_TECH_SPECIALITY,
      vp: 5,
      description: "Have 4 techs of the same color.",
    };
    objectives[Objectives.NAME_TECH_RED_1] = {
      name: Objectives.NAME_TECH_RED_1,
      vp: 3,
      description: "Have 2 red techs.",
    };
    objectives[Objectives.NAME_TECH_BLUE_1] = {
      name: Objectives.NAME_TECH_BLUE_1,
      vp: 3,
      description: "Have 2 blue techs.",
    };
    objectives[Objectives.NAME_TECH_GREEN_1] = {
      name: Objectives.NAME_TECH_GREEN_1,
      vp: 3,
      description: "Have 2 green techs.",
    };
    objectives[Objectives.NAME_TECH_YELLOW_1] = {
      name: Objectives.NAME_TECH_YELLOW_1,
      vp: 3,
      description: "Have 2 yellow techs.",
    };
    objectives[Objectives.NAME_TECH_DIVERSITY] = {
      name: Objectives.NAME_TECH_DIVERSITY,
      vp: 6,
      description: "Have 1 tech of each color.",
    };
    objectives[Objectives.NAME_STRUCTURES_CANON_1] = {
      name: Objectives.NAME_STRUCTURES_CANON_1,
      vp: 5,
      description: "Have at least 5 planetary canons.",
    };
    objectives[Objectives.NAME_STRUCTURES_FACTORY_1] = {
      name: Objectives.NAME_STRUCTURES_FACTORY_1,
      vp: 5,
      description: "Have 4 at least 3 factories.",
    };
    objectives[Objectives.NAME_STRUCTURES_AO_1] = {
      name: Objectives.NAME_STRUCTURES_AO_1,
      vp: 4,
      description: "Have at least 3 assembly orbitals.",
    };
    objectives[Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1] = {
      name: Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1,
      vp: 4,
      description: "Have at least 5 planetary shields.",
    };

    objectives[Objectives.NAME_ARTIFACT_1] = {
      name: Objectives.NAME_ARTIFACT_1,
      vp: 5,
      description: "Have 4 artifacts.",
    };
    objectives[Objectives.NAME_RELIC_1] = {
      name: Objectives.NAME_RELIC_1,
      vp: 5,
      description: "Have 3 relics of other factions.",
    };
    objectives[Objectives.NAME_DESTROYER_1] = {
      name: Objectives.NAME_DESTROYER_1,
      vp: 5,
      description: "Have at least one destroyer in 6 different systems.",
    };
    objectives[Objectives.NAME_CRUISER_1] = {
      name: Objectives.NAME_CRUISER_1,
      vp: 5,
      description: "Have at least one cruiser in 5 different systems.",
    };
    objectives[Objectives.NAME_DREADNOUGHT_1] = {
      name: Objectives.NAME_DREADNOUGHT_1,
      vp: 5,
      description: "Have at least one dreadnought in 4 different systems.",
    };
    objectives[Objectives.NAME_CARRIER_1] = {
      name: Objectives.NAME_CARRIER_1,
      vp: 5,
      description: "Have at least 6 carriers.",
    };
    objectives[Objectives.NAME_MECH_1] = {
      name: Objectives.NAME_MECH_1,
      vp: 5,
      description: "Have at least one mech on 4 different planets",
    };
    return objectives;
  };

  static getImageUrl = (name) => {
    switch (name) {
      case Objectives.NAME_SPEND_SCIENCE_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_SCIENCE_1;
      case Objectives.NAME_SPEND_MINERAL_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_MINERAL_1;
      case Objectives.NAME_SPEND_ENERGY_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_ENERGY_1;
      case Objectives.NAME_SPEND_CREDIT_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_CREDIT_1;
      case Objectives.NAME_SPEND_POPULATION_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_POPULATION_1;
      case Objectives.NAME_PLANET_ICE_WATER_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_ICE_WATER_1;
      case Objectives.NAME_PLANET_EARTH_DESERT_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_EARTH_DESERT_1;
      case Objectives.NAME_PLANET_GAZ_VOLCANIC_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_GAZ_VOLCANIC_1;
      case Objectives.NAME_EMPTY_SPACE_1:
        return ImagesUrl.URL_OBJECTIVE_EMPTY_SPACE_1;
      case Objectives.NAME_WHORMHOLE_1:
        return ImagesUrl.URL_OBJECTIVE_WHORMHOLE_1;
      case Objectives.NAME_MINOR_FACTION_1:
        return ImagesUrl.URL_OBJECTIVE_MINOR_FACTION_1;
      case Objectives.NAME_SPACE_MIDDLE_1:
        return ImagesUrl.URL_OBJECTIVE_SPACE_MIDDLE_1;
      case Objectives.NAME_PLANET_MIDDLE_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_MIDDLE_1;
      case Objectives.NAME_SPACE_FAR_1:
        return ImagesUrl.URL_OBJECTIVE_SPACE_FAR_1;
      case Objectives.NAME_PLANET_FAR_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_FAR_1;
      case Objectives.NAME_SPACE_HS_1:
        return ImagesUrl.URL_OBJECTIVE_SPACE_HS_1;
      case Objectives.NAME_PLANET_HS_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_HS_1;
      case Objectives.NAME_TECH_RED_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_RED_1;
      case Objectives.NAME_TECH_BLUE_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_BLUE_1;
      case Objectives.NAME_TECH_GREEN_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_GREEN_1;
      case Objectives.NAME_TECH_YELLOW_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_YELLOW_1;
      case Objectives.NAME_TECH_DIVERSITY_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_DIVERSITY_1;
      case Objectives.NAME_STRUCTURES_CANON_1:
        return ImagesUrl.URL_OBJECTIVE_STRUCTURES_CANON_1;
      case Objectives.NAME_STRUCTURES_FACTORY_1:
        return ImagesUrl.URL_OBJECTIVE_STRUCTURES_FACTORY_1;
      case Objectives.NAME_STRUCTURES_AO_1:
        return ImagesUrl.URL_OBJECTIVE_STRUCTURES_AO_1;
      case Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1:
        return ImagesUrl.URL_OBJECTIVE_STRUCTURES_PLANETARY_SHIELS_1;
      case Objectives.NAME_ARTIFACT_1:
        return ImagesUrl.URL_OBJECTIVE_ARTIFACT_1;
      case Objectives.NAME_RELIC_1:
        return ImagesUrl.URL_OBJECTIVE_RELIC_1;
      case Objectives.NAME_DESTROYER_1:
        return ImagesUrl.URL_OBJECTIVE_DESTROYER_1;
      case Objectives.NAME_CRUISER_1:
        return ImagesUrl.URL_OBJECTIVE_CRUISER_1;
      case Objectives.NAME_DREADNOUGHT_1:
        return ImagesUrl.URL_OBJECTIVE_DREADNOUGHT_1;
      case Objectives.NAME_CARRIER_1:
        return ImagesUrl.URL_OBJECTIVE_CARRIER_1;
      case Objectives.NAME_MECH_1:
        return ImagesUrl.URL_OBJECTIVE_MECH_1;
      default:
        throw new Error("No image found for objective: " + name);
    }
  };

  static generateRandomObjectivesArray(size) {
    //Prepare faction desc array
    const objectivesList = Object.values(this.getSelectedObjectives());

    let objectiveDeck = JSON.parse(JSON.stringify(objectivesList));
    /*for (let i = 0; i < 1; i++) {
      objectiveDeck = objectiveDeck.concat(
        JSON.parse(JSON.stringify(objectivesList))
      );
    }*/

    CustomMath.shuffleArray(objectiveDeck);

    return objectiveDeck;
  }

  static createObjectiveForGame(size) {
    const objectiveDeck = this.generateRandomObjectivesArray(size);
    const objectives = [];
    for (let i = 0; i < size; i++) {
      const objective = this.create(objectiveDeck[i]);
      objectives.push(objective.name);
    }
    return objectives;
  }

  static setPublicObjectives(playerData, objectives) {
    playerData.publicObjectives = objectives;
  }

  static getPublicObjectives(playerData) {
    return playerData.publicObjectives;
  }

  static setScoredObjectives(playerData, objectives) {
    playerData.scoredObjectives = objectives;
  }

  static scoreObjective(playerData, objectiveName) {
    playerData.scoredObjectives.push(objectiveName);
  }

  static hasScoredObjective(playerData, objectiveName) {
    return playerData.scoredObjectives.includes(objectiveName);
  }
}

module.exports = Objectives;

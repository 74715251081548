const LogBook = require("../Connection/LogBook.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Fleet = require("./Fleet.js");
const Unit = require("./Unit.js");
const LogMessage = require("../Connection/LogMessage.js");
const UIMessage = require("../Connection/UIMessage.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const Rules = require("../Game/Rules.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");
const Tech = require("../Technology/Tech.js");
const Bonus = require("../Bonus/PlanetBonus.js");
const Item = require("../Transactions/Item.js");

class Planet {
  /*static canReceivePopulation(planet, population) {
    if (planet.population + population <= planet.size) {
      return true;
    }
    throw new Error(
      planet.name +
        " can't receive more population. The maximum population it can received is equal to its size, which is " +
        planet.size
    );
  }*/

  static createPlanetData() {
    const data = {
      fleets: [],
      population: 0,
      unassignedPop: 0,
      popOnMineral: 0,
      popOnScience: 0,
      popOnEnergy: 0,
      faction: null,
      isPlanet: true,
      isDiscoveredAtStart: true,
      name: null,
      mineral: 0,
      invade: [],
      endRoundData: {},
      hasProduced: false,
      hasBuiltStructure: false,
      hasBuiltUnits: false,
      combatLog: LogBook.createLogBook(),

      //Bonus
      bonus: [],
    };

    //Generate first bonus
    const rand = CustomMath.generateRandomNumber(1, 100);
    if (rand < 70) {
      const bonus = Bonus.createRandomBonus();
      data.bonus.push(bonus.name);
    }

    return data;
  }

  static didBuildUnits(planet) {
    return planet.hasBuiltUnits;
  }

  static didProduce(planet) {
    return planet.hasProduced;
  }

  static setHasBuiltUnits(planet, value) {
    planet.hasBuiltUnits = value;
  }

  static setHasProduced(planet, value) {
    planet.hasProduced = value;
  }

  static addInvade(planet, factionName) {
    if (!planet.invade) {
      planet.invade = [];
    }

    //If it already contains, skip
    if (planet.invade.includes(factionName)) {
      return;
    }
    planet.invade.push(factionName);
  }

  static removeInvade(planet, factionName) {
    if (!planet.invade) {
      return;
    }
    const index = planet.invade.indexOf(factionName);
    if (index !== -1) {
      planet.invade.splice(index, 1);
    }
  }

  static addInvades(planet, factionNames) {
    if (!planet.invade) {
      planet.invade = [];
    }
    for (let i = 0; i < factionNames.length; i++) {
      if (!planet.invade.includes(factionNames[i])) {
        planet.invade.push(factionNames[i]);
      }
    }
  }

  static isFactionInvading(planet, factionName) {
    if (!planet.invade) {
      return false;
    }
    return planet.invade.includes(factionName);
  }

  static getMaximumPopulation(playerData, planet) {
    return planet.size;
  }

  static spendMineral(playerData, planet, amount) {
    if (planet.mineral < amount) {
      throw new Error(
        "Not enough mineral on " +
          planet.name +
          ". Trying to spend " +
          amount +
          " but only have " +
          planet.mineral
      );
    }
    planet.mineral = CustomMath.roundDec(planet.mineral - amount);
  }

  static spendPopulation(playerData, planet, amount) {
    if (planet.unassignedPop < amount) {
      throw new Error(
        "Not enough available population on " +
          planet.name +
          ". Trying to spend " +
          amount +
          " but only have " +
          planet.unassignedPop +
          " which is not assigned to a task."
      );
    }
    Planet.removePopulation(planet, amount);
  }

  /*static spendCost(playerData, planet, cost) {
    if (planet.mineral < cost.mineral) {
      throw new Error(
        "Not enough mineral on " +
          planet.name +
          ". Trying to spend " +
          cost.mineral +
          " but only have " +
          planet.mineral
      );
    }
    if (planet.population < cost.population) {
      throw new Error(
        "Not enough population on " +
          planet.name +
          ". Trying to spend " +
          cost.population +
          " but only have " +
          planet.population
      );
    }
    planet.mineral = CustomMath.roundDec(planet.mineral - cost.mineral);
    planet.science = CustomMath.roundDec(planet.science - cost.science);
  }*/

  //Fleets
  static getFleet(planet, faction) {
    //Get a fleet from a planet
    let fleetIndex = planet.fleets.findIndex(
      (fleet) => fleet.faction === faction
    );
    if (fleetIndex == -1) {
      return null;
    } else {
      if (Fleet.isEmpty(planet.fleets[fleetIndex])) {
        // This check is needed because during the update of the global map, some planet may have empty fleets
        planet.fleets.splice(fleetIndex, 1);
        return null;
      }
      return planet.fleets[fleetIndex];
    }
  }

  static getFactionsOnPlanet(planet) {
    const factions = [];
    for (let i = 0; i < planet.fleets.length; i++) {
      factions.push(planet.fleets[i].faction);
    }
    return factions;
  }

  //Get

  static getConstructionValue = (playerData, planet, showOnlyBase = false) => {
    let modifier = 0;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.quantumAssemblyLine.name)
    ) {
      modifier = modifier + 1;
    }
    if (Item.playerHasExhaustedItem(playerData, Item.NAME_RYNZORATH_SCROLL)) {
      modifier = modifier + 3;
    }

    const base = planet.construction;

    const countBonus = Bonus.getArrayCountBonus(planet.bonus, Bonus.NAME_BUILD);
    if (showOnlyBase) {
      return base;
    } else {
      return base + countBonus * 2 + modifier;
    }
  };

  static getUnitFromId(planet, unitId) {
    for (let i = 0; i < planet.fleets.length; i++) {
      const unit = Fleet.getUnitFromId(planet.fleets[i], unitId);
      if (unit) {
        return unit;
      }
    }
    return null;
  }

  static getUnitsFromTypeFromFaction(planet, faction, type) {
    const fleet = Planet.getFleet(planet, faction);
    if (!fleet) {
      return [];
    }
    return Fleet.getUnits(fleet).filter((unit) => unit.type === type);
  }

  static getFleets(planet) {
    return planet.fleets;
  }

  static addFleetToPlanet(planet, fleet) {
    const fleetTo = Planet.getFleet(planet, fleet.faction);
    if (fleetTo) {
      Fleet.addFleetToFleet(fleet, fleetTo);
    } else {
      planet.fleets.push(fleet);
    }
  }

  static removeFleetFromPlanet(planet, fleetToRemove) {
    const targetFleet = Planet.getFleet(planet, fleetToRemove.faction);
    if (!targetFleet) {
      return;
    }
    Fleet.removeFleetFromFleet(fleetToRemove, targetFleet);
  }

  static getFleetOrCreate(playerData, planet, faction, color) {
    let fleet = Planet.getFleet(planet, faction);
    if (!fleet) {
      fleet = Fleet.createFleet(playerData, faction);
      Planet.addFleetToPlanet(planet, fleet);
    }
    return fleet;
  }

  static addFleetToPlanet(planet, fleet) {
    const fleetTo = Planet.getFleet(planet, fleet.faction);
    if (fleetTo) {
      Fleet.addFleetToFleet(fleet, fleetTo);
    } else {
      planet.fleets.push(fleet);
    }
  }

  static removeFleetsExeptFaction(planet, faction) {
    planet.fleets = planet.fleets.filter((fleet) => fleet.faction === faction);
  }

  static removeFleetFromFaction(planet, faction) {
    planet.fleets = planet.fleets.filter((fleet) => fleet.faction !== faction);
  }

  static hasSomeUnits(planet) {
    for (let i = 0; i < planet.fleets.length; i++) {
      if (!Fleet.isEmpty(planet.fleets[i])) {
        return true;
      }
    }
    return false;
  }

  //Resources
  static assignPopulationOnTransfer(planet, amount) {
    /*CustomMath.checkInteger(amount);

    if (amount > 0 && amount > planet.unassignedPop) {
      throw new Error(
        "Can't assign more population than population available on the planet"
      );
    }
    if (amount < 0 && -amount > planet.popOnTransfer) {
      throw new Error(
        "Can't remove more population than population assigned on transfer"
      );
    }
    planet.popOnTransfer += amount;
    planet.unassignedPop -= amount;*/
  }

  static clearPlanet(planet) {
    planet.invade = [];
  }

  static adminAfterRound(playerData, planet) {
    planet.unassignedPop = planet.population;
    planet.popOnMineral = 0;
    planet.popOnScience = 0;
    planet.popOnEnergy = 0;
    planet.hasProduced = false;
    planet.hasBuiltUnits = false;
    planet.hasBuiltStructure = false;
  }

  static unMergeFromGlobalToPlayer(globalPlanet, playerPlanet, playerData) {
    //Planet.clearLogs(playerPlanet);
    /*LogAttachment.clearLogs(playerPlanet);
    LogAttachment.setpreviousLog(
      playerPlanet,
      LogAttachment.getLogActivity(globalPlanet),
      LogAttachment.LOG_ACTIVITY
    );

    LogAttachment.setpreviousLog(
      playerPlanet,
      LogAttachment.getLogCombat(globalPlanet),
      LogAttachment.LOG_COMBAT
    );*/

    playerPlanet.combatLog = globalPlanet.combatLog;

    //Other
    playerPlanet.combatKey = globalPlanet.combatKey;
    playerPlanet.mineral = globalPlanet.mineral;
    playerPlanet.population = globalPlanet.population;
    playerPlanet.fleets = globalPlanet.fleets;
    playerPlanet.faction = globalPlanet.faction;
    playerPlanet.endRoundData = globalPlanet.endRoundData;
  }

  static mergeFromPlayerToGLobal(globalPlanet, playerPlanet, playerData) {
    /*LogBook.concat(
      LogAttachment.getLogActivity(playerPlanet),
      LogAttachment.getLogActivity(globalPlanet)
    );
    LogBook.concat(
      LogAttachment.getLogCombat(playerPlanet),
      LogAttachment.getLogCombat(globalPlanet)
    );*/

    globalPlanet.combatLog = LogBook.createLogBook();
    playerPlanet.combatLog = LogBook.createLogBook();

    /*if (globalPlanet.name === "Selenia D") {
      console.log("Selenia D");
      console.log("mergeFromPlayerToGLobal PLAYER");
      console.log(playerPlanet.faction);
      console.log(playerData.faction.name);
      console.log(playerPlanet.fleets);
    }*/
    if (playerPlanet.faction === playerData.faction.name) {
      globalPlanet.faction = playerPlanet.faction;
      globalPlanet.fleets = playerPlanet.fleets;
      globalPlanet.mineral = playerPlanet.mineral;
      globalPlanet.population = playerPlanet.population;
      globalPlanet.unassignedPop = playerPlanet.unassignedPop;
      globalPlanet.popOnMineral = playerPlanet.popOnMineral;
      globalPlanet.popOnScience = playerPlanet.popOnScience;
    }
    /*if (globalPlanet.name === "Selenia D") {
      console.log("Selenia D");
      console.log("mergeFromPlayerToGLobal GLOBAL");
      console.log(globalPlanet.fleets);
      console.log(globalPlanet.faction);
    }*/
    Planet.addInvades(globalPlanet, playerPlanet.invade);
  }

  //Log
  static getCombatLog(planet) {
    return planet.combatLog;
  }

  static generateLogCombat(planet, text, itemArray = []) {
    const logBook = this.getCombatLog(planet);
    LogBook.correctCursor(logBook);
    LogBook.generateAddMessage(logBook, text, itemArray);
  }

  static clearLogs(planet) {
    planet.combatLog = LogBook.createLogBook();
  }

  //Resources

  static getConsumedEnergy(playerData, planet) {
    if (planet.faction !== playerData.faction.name) {
      return 0;
    }
    const fleet = Planet.getFleet(planet, playerData.faction.name);
    const structure = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE).filter(
      (unit) => unit.type === Unit.UNIT_TYPE_SUPPLY_BASE
    );
    return CustomMath.roundDec(
      structure.length * Rules.SUPPLY_BASE_ENERGY_CONSUMPTION
    );
  }

  static getFleetLimitGain(playerData, planet) {
    if (planet.faction !== playerData.faction.name) {
      return 0;
    }
    const fleet = Planet.getFleet(planet, playerData.faction.name);
    const structure = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE).filter(
      (unit) => unit.type === Unit.UNIT_TYPE_SUPPLY_BASE
    );
    return CustomMath.roundDec(
      structure.length * Rules.SUPPLY_BASE_FLEET_LIMIT_GAIN
    );
  }

  static getPopulationGrow(playerData, planet) {
    let modifiers = 0;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.ecoHarvestFarming.name)
    ) {
      modifiers = modifiers + 0.15;
    }
    return CustomMath.roundDec(planet.populationGrow * (1 + modifiers));
  }

  static getAmountAssignedPopulation(planet) {
    return CustomMath.roundDec(
      planet.popOnMineral + planet.popOnScience + planet.popOnEnergy
    );
  }

  static getPopulationEfficiency(playerData, planet) {
    let modifier = 0;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.ecoHarvestFarming.name)
    ) {
      modifier = modifier + 0.3;
    }

    return CustomMath.roundDec(planet.populationEfficiency * (1 + modifier));
  }

  static getPopulationProduction(playerData, planet, showOnlyBase = false) {
    let base = CustomMath.roundDec(
      this.getPopulationEfficiency(playerData, planet)
    );

    let modifier = 0;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.subterraneanHabitat.name)
    ) {
      modifier = modifier + 0.4;
    }

    const countBonus = Bonus.getArrayCountBonus(
      planet.bonus,
      Bonus.NAME_POPULATION
    );
    if (showOnlyBase) {
      return base;
    } else {
      return CustomMath.roundDec(base + countBonus + modifier);
    }
  }

  static producePopulation(playerData, planet) {
    playerData.population = CustomMath.roundDec(
      playerData.population + this.getPopulationProduction(playerData, planet)
    );
  }

  static getMineralEfficiency(playerData, planet) {
    let modifier = 0;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.quantumExcavation.name)
    ) {
      modifier = modifier + 0.3;
    }

    return CustomMath.roundDec(planet.mineralEfficiency * (1 + modifier));
  }

  static getMineralProduction(playerData, planet, showOnlyBase = false) {
    /*if (!popOnMineral)
      return CustomMath.roundDec(
        this.getMineralEfficiency(playerData, planet) * planet.popOnMineral
      );*/
    const base = CustomMath.roundDec(
      Planet.getMineralEfficiency(playerData, planet)
    );

    const countBonus = Bonus.getArrayCountBonus(
      planet.bonus,
      Bonus.NAME_MINERAL
    );
    if (showOnlyBase) {
      return base;
    } else {
      return base + countBonus;
    }
  }

  static produceMineral(playerData, planet) {
    playerData.mineral = CustomMath.roundDec(
      playerData.mineral + this.getMineralProduction(playerData, planet)
    );
  }

  static getScienceEfficiency(playerData, planet) {
    let modifier = 0;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.neuralNexusGrid.name)
    ) {
      modifier = modifier + 0.3;
    }
    return CustomMath.roundDec(planet.scienceEfficiency * (1 + modifier));
  }

  static getEnergyEfficiency(playerData, planet) {
    let modifier = 0;
    if (TechTree.hasPlayerTech(playerData, TechList.TECH.voltCores.name)) {
      modifier = modifier + 0.3;
    }

    return CustomMath.roundDec(planet.energyEfficiency * (1 + modifier));
  }

  static getScienceProduction(playerData, planet, showOnlyBase = false) {
    const base = CustomMath.roundDec(
      Planet.getScienceEfficiency(playerData, planet)
    );

    const countBonus = Bonus.getArrayCountBonus(
      planet.bonus,
      Bonus.NAME_RESEARCH
    );
    if (showOnlyBase) {
      return base;
    } else {
      return base + countBonus;
    }
  }

  static produceScience(playerData, planet) {
    playerData.science = CustomMath.roundDec(
      playerData.science + this.getScienceProduction(playerData, planet)
    );
  }

  static getAmountGenerator(playerData, planet) {
    const fleet = Planet.getFleet(planet, playerData.faction.name);
    const structure = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE).filter(
      (unit) => unit.type === Unit.UNIT_TYPE_GENERATOR
    );
    return structure.length;
  }

  static getEnergyProduction(playerData, planet, showOnlyBase = false) {
    /*if (!popOnEnergy)
      return CustomMath.roundDec(
        Planet.getEnergyEfficiency(playerData, planet) * planet.popOnEnergy +
          aquaFustionModifier +
          cryoHarvestModifier
      );*/
    const base = CustomMath.roundDec(
      Planet.getEnergyEfficiency(playerData, planet)
    );

    const countBonus = Bonus.getArrayCountBonus(
      planet.bonus,
      Bonus.NAME_ENERGY
    );
    if (showOnlyBase) {
      return base;
    } else {
      return base + countBonus;
    }
  }

  static produceEnergy(playerData, planet) {
    playerData.energy = CustomMath.roundDec(
      playerData.energy + this.getEnergyProduction(playerData, planet)
    );
  }

  static getCreditProduction(playerData, planet, showOnlyBase = false) {
    return CustomMath.roundDec(0);
  }

  static produceCredit(playerData, planet) {
    playerData.credit = CustomMath.roundDec(
      playerData.credit + this.getCreditProduction(playerData, planet)
    );
  }

  static addMineral(planet, amount) {
    planet.mineral = CustomMath.roundDec(planet.mineral + amount);
  }

  //Population assignmemnt

  static addPopulation(planet, amount) {
    planet.population = CustomMath.roundDec(planet.population + amount);
    planet.unassignedPop = CustomMath.roundDec(planet.unassignedPop + amount);
  }

  static removePopulation(planet, amount) {
    planet.population = CustomMath.roundDec(
      Math.max(0, planet.population - amount)
    );

    //Remove popOnScience
    const amountToRemoveFromMineral = Math.min(planet.popOnScience, amount);
    const amountToRemoveFromScience = Math.min(
      planet.popOnScience,
      amount - amountToRemoveFromMineral
    );
    planet.popOnMineral = CustomMath.roundDec(
      planet.popOnMineral - amountToRemoveFromMineral
    );
    planet.popOnScience = CustomMath.roundDec(
      planet.popOnScience - amountToRemoveFromScience
    );
    planet.unassignedPop = CustomMath.roundDec(
      planet.population - planet.popOnMineral - planet.popOnScience
    );
  }

  static assignPopOnMineral(planet, amount) {
    CustomMath.checkInteger(amount);
    if (amount > 0 && amount > planet.unassignedPop) {
      throw new Error(
        "Can't assign more population than population available on the planet"
      );
    }
    if (amount < 0 && -amount > planet.popOnMineral) {
      throw new Error(
        "Can't remove more population than population assigned on mineral"
      );
    }
    planet.popOnMineral = CustomMath.roundDec(planet.popOnMineral + amount);
    planet.unassignedPop = CustomMath.roundDec(planet.unassignedPop - amount);
  }

  static assignPopOnScience(planet, amount) {
    CustomMath.checkInteger(amount);

    if (amount > 0 && amount > planet.unassignedPop) {
      throw new Error(
        "Can't assign more population than population available on the planet"
      );
    }
    if (amount < 0 && -amount > planet.popOnScience) {
      throw new Error(
        "Can't remove more population than population assigned on science"
      );
    }
    planet.popOnScience = CustomMath.roundDec(planet.popOnScience + amount);
    planet.unassignedPop = CustomMath.roundDec(planet.unassignedPop - amount);
  }

  static assignPopOnEnergy(planet, amount) {
    CustomMath.checkInteger(amount);

    if (amount > 0 && amount > planet.unassignedPop) {
      throw new Error(
        "Can't assign more population than population available on the planet"
      );
    }
    if (amount < 0 && -amount > planet.popOnEnergy) {
      throw new Error(
        "Can't remove more population than population assigned on energy"
      );
    }
    planet.popOnEnergy = CustomMath.roundDec(planet.popOnEnergy + amount);
    planet.unassignedPop = CustomMath.roundDec(planet.unassignedPop - amount);
  }

  static checkAfterPlacingPop(playerData, planet) {
    if (planet.population > planet.size) {
      throw new Error(
        "Can't place more population than the size of the planet"
      );
    }
  }

  static warningPopulationCapBeforeProduction(playerData, planet, callback) {
    const populationProduction = Planet.getPopulationProduction(
      playerData,
      planet
    );
    const totalPopulation = planet.population + populationProduction;

    if (totalPopulation > planet.size) {
      UIMessage.displayConfirmMessage(
        planet.name + " will be overcrowded",
        "The population of the planet will be capped at its size : " +
          planet.size +
          ". Do you want to continue ?",
        callback
      );
    } else {
      callback();
    }
  }

  static getMaxAmountPopulation(playerData, planet) {
    let maxPop = planet.size;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.subterraneanHabitat.name)
    ) {
      maxPop = maxPop + 12;
    }
    return maxPop;
  }

  static capPopulation(playerData, planet) {
    if (planet.population > this.getMaxAmountPopulation(playerData, planet)) {
      Planet.removePopulation(
        planet,
        planet.population - this.getMaxAmountPopulation(playerData, planet)
      );
      UIMessage.sendInfoMessageToClient(
        playerData,
        planet.name + " overcrowded.",
        "Population on " +
          planet.name +
          " has been capped at " +
          this.getMaxAmountPopulation(playerData, planet) +
          "."
      );
      LogAttachment.logActivity(planet, [
        {
          content:
            "Population on " +
            planet.name +
            " has been capped at " +
            planet.size +
            ", removing " +
            (planet.population -
              this.getMaxAmountPopulation(playerData, planet)) +
            " population.",
        },
      ]);
    }
  }

  //Size
  static getAmountUsedSize(planet) {
    const fleetStructure = Planet.getFleet(planet, planet.faction);
    const structures = Fleet.getUnits(
      fleetStructure,
      Fleet.UNIT_CLASS_STRUCTURE
    );

    let amountSize = 0;
    for (let i = 0; i < structures.length; i++) {
      amountSize += structures[i].size;
    }
    return amountSize;
  }

  static checkSizeUsed(playerData, planet) {
    const amountUsedSize = Planet.getAmountUsedSize(planet);
    if (amountUsedSize > Rules.AMOUNT_STRUCTURE_PER_PLANET_MAX) {
      throw new Error(
        "No enough space on " +
          planet.name +
          ". You can only have  " +
          Rules.AMOUNT_STRUCTURE_PER_PLANET_MAX +
          "  structures maximum on a planet."
      );
    }
  }

  //Unit
  static addUnit(playerData, planet, unit) {
    const fleet = Planet.getFleetOrCreate(planet, playerData.faction.name);
    Fleet.addUnit(fleet, unit);
  }

  static removeUnit(playerData, planet, unit) {
    const fleets = Planet.getFleets(planet);
    for (let i = 0; i < fleets.length; i++) {
      if (Fleet.removeUnit(fleets[i], unit)) {
        return true;
      }
    }
    return false;
  }

  static isUnitOnPlanet(planet, unit) {
    const fleets = Planet.getFleets(planet);
    for (let i = 0; i < fleets.length; i++) {
      if (Fleet.isUnitInFleet(fleets[i], unit)) {
        return true;
      }
    }
    return false;
  }

  //Exhaust
  static exhaust(planet) {
    planet.hasProduced = true;
  }

  static unExhaust(planet) {
    planet.hasProduced = false;
  }

  static isExhausted(planet) {
    return planet.hasProduced;
  }

  //Bonus
  static addBonus(planet, bonus) {
    if (!planet.bonus) {
      planet.bonus = [];
    }
    planet.bonus.push(bonus.name);
  }

  static removeBonus(planet, bonus) {
    if (!planet.bonus) {
      return;
    }
    const index = planet.bonus.indexOf(bonus.name);
    if (index !== -1) {
      planet.bonus.splice(index, 1);
    }
  }
}

module.exports = Planet;

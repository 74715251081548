import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import LogMessageView from "./LogMessageView";

const LogBook = require("../../../Data/GameData/Connection/LogBook");

const LogBookView = ({ logBook, textAlign = "left" }) => {
  console.log("LogBookView", logBook);

  const DisplayLogMessage = ({ treeItem }) => {
    const [collapsed, setCollapsed] = useState(treeItem.collapsed);
    const collapsable = treeItem.collapsed;

    return (
      <div style={{}}>
        <div
          style={{}}
          onClick={(e) => {
            e.preventDefault();
            setCollapsed((prevCollapsed) => !prevCollapsed);
          }}
        >
          <div
            style={{
              paddingTop: "0.5em",
              paddingBottom: "0.5em",
              //backgroundColor: "grey",
            }}
          >
            {collapsable && <span>+ </span>}
            <LogMessageView logMessage={treeItem.logMessage}></LogMessageView>
          </div>

          {collapsed === false &&
            treeItem.subLogTree.map((childTreeItem, index) => {
              return (
                <div key={index} style={{ marginLeft: "2em" }}>
                  <DisplayLogMessage
                    treeItem={childTreeItem}
                  ></DisplayLogMessage>{" "}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div style={{ textAlign: textAlign, fontSize: "0.75em" }}>
      {logBook.logTree.map((treeItem, index) => {
        return (
          <div key={index} style={{}}>
            <DisplayLogMessage treeItem={treeItem}></DisplayLogMessage>
          </div>
        );
      })}
    </div>
  );
};

export default LogBookView;

const MinorFactionList = require("./MinorFactionList.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const { Description } = require("@headlessui/react");
const SecondaryObject = require("../MapData/SecondaryObject.js");
const Rules = require("../Game/Rules.js");
const Faction = require("../PlayerData/Faction.js");
const Fleet = require("../MapData/Fleet.js");
const Unit = require("../MapData/Unit.js");
const ActivePassiveAbility = require("../Ability/ActivePassiveAbility.js");
const Item = require("../Transactions/Item.js");

class MinorFactionAbility {
  static getAbilities = () => {
    const abilities = {};

    //Want to add pirate ability to add destroyer in adjacent system

    abilities[Faction.NAME_E_AETHERION] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Research a tech. You can ignore one prerequisite during this research.",
        true
      ),
    };

    abilities[Faction.NAME_E_ANDAR] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Place a factory on a planet you control.",
        true
      ),
    };

    abilities[Faction.NAME_E_BOREALIS] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Choose a planet which has already built units this round. This planet can build units again this round.",
        true
      ),
    };

    abilities[Faction.NAME_E_CALVARIA] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Calvaria scroll.", //When exausted, your units combat value is increase by 0.2.
        true,
        [Item.create(null, Item.NAME_CALVARIA_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_DELPHAS] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Delphas scroll.", //When exausted, hits produced by Orbital Canons assigned to your units are devided by 2.
        true,
        [Item.create(null, Item.NAME_DELPHAS_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_DRAKOR] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Place 4 " + Faction.NAME_E_DRAKOR + " crusers on an adjacent system.",
        true
      ),
    };

    abilities[Faction.NAME_E_ERINYS] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Choose 2 planets which have already produced resources this round. They can produce again resources this round.",
        true
      ),
    };

    abilities[Faction.NAME_E_EROSIUM] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Change the type of a planet to a type you choose. Its basic resource production values will be updated.",
        true
      ),
    };

    abilities[Faction.NAME_E_FELTOR] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Remove a portal in a system you choose.",
        true
      ),
    };

    abilities[Faction.NAME_E_GHELOR] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Place an assembly orbital on a planet you control.",
        true
      ),
    };

    abilities[Faction.NAME_E_HELKARAN] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(null, "Gain 6 energy.", true),
    };

    abilities[Faction.NAME_E_ICANTA] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain an Icanta scroll", //Icanta scroll When exausted, your planetary canons have +0.8 to their Space Canon Defense and Planetary Canon Defense abilities.
        true,
        [Item.create(null, Item.NAME_ICANTA_SCROLL)]
      ),
    };
    abilities[Faction.NAME_E_JELORIA] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Jeloria scroll", //Exhaust this artifact to ready one artifact you own.
        true,
        [Item.create(null, Item.NAME_JELORIA_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_KORATH] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Place 2 mechs on any planets you control.",
        true
      ),
    };

    abilities[Faction.NAME_E_KOBAMDA] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Kobamda scroll", //Gain 1 vp per ice and oceanic planet you control.
        true,
        [Item.create(null, Item.NAME_KOBAMDA_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_LARAN] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Choose a system including a planet you control. All your units are fully repaired in that system.",
        true
      ),
    };

    abilities[Faction.NAME_E_MALTRION] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Maltrion scroll.", //When exhausted, during the first round of any combat, cancel 4 hits assigned to your units.
        true,
        [Item.create(null, Item.NAME_MALTRION_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_MINARI] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(null, "Gain 6 minerals.", true),
    };

    abilities[Faction.NAME_E_OPHARIAN] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(null, "Gain 8 sciences.", true),
    };

    abilities[Faction.NAME_E_PROTHIAN] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Place one dread on a system including at least one of your ship.",
        true
      ),
    };

    abilities[Faction.NAME_E_QUORIDIOM] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Quoridiom scroll", //When exausted, when you take control of a planet, before the ennemy structures are destroyed, you replace them with your own structures.
        true,
        [Item.create(null, Item.NAME_QUORIDIOM_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_RYNZORATH] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Rynzorath scroll", //When exausted, all of your planets can build 3 more units.
        true,
        [Item.create(null, Item.NAME_RYNZORATH_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_SELTAAR] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Seltaar scroll.", //Your carriers and dreadnoughts have their capacity increased by 1.
        true,
        [Item.create(null, Item.NAME_SELTAAR_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_TERRAN] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Choose an exiled faction. The population cost of its ability is set to its minimum. You can use an exiled faction ability again this round.",
        true
      ),
    };

    abilities[Faction.NAME_E_TAARKIR] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Taarkir scroll.", // When exausted, after your units land on an ennemy planet, destroy one random ground force of your ennemy on this planet.
        true,
        [Item.create(null, Item.NAME_TAARKIR_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_UTARION] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Transform 1 of your crusers into a dreadnought and 2 fighters.",
        true
      ),
    };

    abilities[Faction.NAME_E_VOIDBORN] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain a Voidborn scroll.", //Hits generated by bombardment on your planets are decreased by 4.
        true,
        [Item.create(null, Item.NAME_VOIDBORN_SCROLL)]
      ),
    };

    abilities[Faction.NAME_E_WOLTHAAR] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(null, "Gain 5 credits.", true),
    };

    abilities[Faction.NAME_E_XELPHAR] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Gain 8 populations.",
        true
      ),
    };

    abilities[Faction.NAME_E_XENOS] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Choose up to 3 planets you control. Place up to one planetary canon on these planets.",
        true
      ),
    };

    abilities[Faction.NAME_E_ZYLORIAN] = {
      populationCostMin: 0,
      ability: ActivePassiveAbility.createDesc(
        null,
        "Choose up to 3 different planet you control. Each of them gains one random bonus.",
        true
      ),
    };

    return abilities;
  };
}

module.exports = MinorFactionAbility;

import React, { useState, useEffect, useMemo } from "react";
import "../../CSS/Menu.css";

import IconGridView from "./IconGridView";
import IconView from "../Icon/IconView";
import SpaceObjectImageView from "../Object/SpaceObjectImageView";
import GameState from "../../Data/GameData/Game/GameState";
import Logo from "../Utils/Logo";
import FactionLogo from "../Utils/FactionLogo";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView";
import UnitImageView from "../Object/UnitImageView";
import UnitsStackView from "../Units/UnitsStackView";
import ImagesUrl from "../../Data/Other/ImagesUrl.js";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject.js";
import SecondaryObjectView from "./SecondaryObjectView";
import CuriosityView from "./CuriosityView.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData");
const Phase = require("../../Data/GameData/Game/Phase");
const EndRoundReviewCA = require("../../ClientAction/EndRoundReviewCA");
const Unit = require("../../Data/GameData/MapData/Unit.js");

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";
const IMAGE_SHIP_URL = "/Images/Map/Icon/icon_ship.png";
const IMAGE_GROUND_FORCE_URL = "/Images/Map/Icon/icon_ground_force.png";

const ICON_BACKGROUND_OPACITY = 0.75;
const ICON_FONT_SIZE_PC = 100;

const GRID_WIDTH = 5;
const GRID_HEIGHT = 5;

const InformationIcon = ({ system, shouldShowCombatIcon }) => {
  //console.log("DEBUG RENDERING SystemView InformationIcon");

  const combatLength = System.getAllCombatKeys(system).length;
  //const shouldShowCombatIcon = Popup.getShowCombatIcon();
  //console.log("DEBUG InformationIcon : ", shouldShowCombatIcon);

  if (shouldShowCombatIcon && combatLength > 0) {
    return (
      <div
        style={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Logo logoName="space combat"></Logo>
      </div>
    );
  }

  return null;
};

const SystemNameDisplay = ({ system }) => {
  const style = {
    position: "absolute",
    left: `50%`,
    top: `50%`,
    transform: `translate(-50%, -50%)`,
    fontSize: "0.8em",
  };

  const factionColor = system.faction
    ? Color.getColorFromFaction(StaticGameData.getPlayerData(), system.faction)
    : "none";

  const borderStyle = {};

  if (system.faction) {
    borderStyle.borderColor = factionColor;
    borderStyle.borderWidth = "1px";
    borderStyle.borderStyle = "solid";
  }
  return (
    <div style={style}>
      <div
        style={{
          fontSize: "0.5em",
          //backgroundColor: factionColor,
          borderRadius: "5px",
          padding: "2px",
          ...borderStyle,
        }}
      >
        {system.faction && (
          <span style={{ fontSize: "1.5em" }}>
            <FactionLogo factionName={system.faction}></FactionLogo>
          </span>
        )}{" "}
        {system.name}
      </div>
      {false && system.faction && (
        <div>
          <FactionLogo
            factionName={system.faction}
            heightRem="1.5em"
          ></FactionLogo>
        </div>
      )}
    </div>
  );
};

const AllFleetDisplay = ({ system }) => {
  const allFleets = System.getAllFleetsIncludingPlanets(system);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        fontSize: "0.8em",
      }}
    >
      <FleetsSummaryView
        fleets={allFleets}
        onClick={() => {
          //Popup.addLayer({ name: "ManageSystemView", system: { system } });
        }}
        superShort={true}
      ></FleetsSummaryView>
    </div>
  );
};

const FleetDisplay = ({ system }) => {
  const systemFleet = System.getFleets(system);
  const incomingFleets = system.incomingFleets;
  const allFleets = systemFleet.concat(incomingFleets);

  const slots = Fleet.UnitDisplaySlots();
  slots.addEmptySlot(Unit.UNIT_TYPE_INFANTRY, 90, 15);
  slots.addEmptySlot(Unit.UNIT_TYPE_FIGHTER, 90, 50);
  slots.addEmptySlot(Unit.UNIT_TYPE_MECH, 75, 30);
  slots.addEmptySlot(Unit.UNIT_TYPE_DESTROYER, 35, 60);
  slots.addEmptySlot(Unit.UNIT_TYPE_CRUSER, 40, 85);
  slots.addEmptySlot(Unit.UNIT_TYPE_CARRIER, 60, 15);
  slots.addEmptySlot(Unit.UNIT_TYPE_DREADNOUGH, 15, 85);

  for (let i = 0; i < allFleets.length; i++) {
    slots.addFleet(allFleets[i]);
  }

  slots.splitStack();

  return (
    <div>
      {slots.getSlots.map((slot, index) => {
        const scaleUnit = 0.4;
        if (slot.units.length <= 0) return;
        return (
          <div
            key={index}
            style={{
              position: "absolute",
              left:
                system.spaceObjectDisplaySetup === 0
                  ? slot.x + "%"
                  : 100 - slot.x + "%",
              top: slot.y + "%",

              zIndex: 100,
              //backgroundColor: Color.COLOR_LIGHT_SHADOW,
              //transform: `translate(-0%, -${100 - 40 + 20}%)`,
            }}
          >
            <UnitsStackView slot={slot} scale={scaleUnit}></UnitsStackView>
          </div>
        );
      })}
    </div>
  );
};

const DisplayMapConnection = ({ system }) => {
  return;
  if (!system.mapConnection) {
    return;
  }
  let imageUrl =
    ImagesUrl.MAP_CONNECTION_IMAGE_BASE + system.mapConnection.number + ".png";
  let numberUrl =
    ImagesUrl.MAP_CONNECTION_NUMBER_BASE + system.mapConnection.number + ".png";

  const imageStyle = {
    //If maxWidth defined, use it here, otherwise use 100%
    maxWidth: "100%",
    maxHeight: "100%",
    height: "auto",
  };
  if (system.mapConnection.direction === "up") {
    return (
      <div
        style={{
          position: "absolute",
          top: -80,
          left: 0,
          //transform: " translate(-0%, -100%)",
        }}
      >
        <img style={imageStyle} src={imageUrl} alt="connection"></img>
      </div>
    );
  }
  if (system.mapConnection.direction === "down") {
    return (
      <div
        style={{
          position: "absolute",
          top: +170,
          left: 0,
          transform: "scaleY(-1)",
          //transform: " translate(-0%, -100%)",
        }}
      >
        <img style={imageStyle} src={imageUrl} alt="connection"></img>
      </div>
    );
  }

  return <div></div>;
};

const SystemBox = React.memo(
  ({ system, sizeX, fontSizeP, popup, mapDisplayMode }) => {
    //Check if this is adraft system, different display
    let backgroundColor = "none";

    /*if (system.hasBeenActivated) {
    backgroundColor = Color.COLOR_LIGHT_SHADOW;
  }*/

    if (system.isFactionDraftSystem) {
      return (
        <div className="div-fill" style={{ fontSize: "0.5em" }}>
          <DisplayMapConnection system={system}></DisplayMapConnection>
          {system.factionsToPickFrom.map((faction, index) => (
            <div key={index}>
              <span> {faction.name}</span>
            </div>
          ))}
        </div>
      );
    }

    const DisplayPortal = () => {
      if (!system.hasBeenActivated) {
        return;
      }

      return (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //backgroundColor: Color.COLOR_LOWLIGHT,
            width: "auto",
            fontSize: "3em",
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={ImagesUrl.URL_PORTAL}
            alt={"portal"}
          />
        </div>
      );
    };

    const DisplaySpaceObject = ({ spaceObject, popup }) => {
      //No object
      if (spaceObject.type === SecondaryObject.SECONDARY_OBJECT_NONE) {
        return;
      }

      //Planet
      if (spaceObject.isPlanet) {
        return (
          <SpaceObjectImageView
            spaceObject={spaceObject}
            showResources={true}
            showInvasion={true}
            showMapIcon={true}
            showBuildIcon={true}
          ></SpaceObjectImageView>
        );
      } else {
        return (
          <SecondaryObjectView
            spaceObject={spaceObject}
            sizeX={sizeX / 3}
          ></SecondaryObjectView>
        );
      }

      //Other secondary
    };

    //Otherwise return normal system display
    return (
      <div
        className="div-fill"
        style={{
          fontSize: fontSizeP + "px",
          backgroundColor: backgroundColor,
          //outline: borderConnectionLines, // Outer outline not affecting the size
          //backgroundColor: "yellow",
        }}
      >
        {" "}
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          {" "}
          <CuriosityView
            curiosity={system.backgroundObject}
            mapView={true}
          ></CuriosityView>
        </div>
        <DisplayMapConnection system={system}></DisplayMapConnection>
        <DisplayPortal></DisplayPortal>
        <div className="div-ver-center">
          {system.spaceObjectDisplaySetup === 0 && (
            <div>
              <div
                style={{
                  width: "45%",
                  height: "45%",
                  position: "absolute",
                  top: "2.5%",
                  left: "2.5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DisplaySpaceObject
                  spaceObject={system.objects[0]}
                  popup={popup}
                ></DisplaySpaceObject>
              </div>
              <div
                style={{
                  width: "45%",
                  height: "45%",
                  position: "absolute",
                  top: "52.5%",
                  left: "52.5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DisplaySpaceObject
                  spaceObject={system.objects[1]}
                  popup={popup}
                ></DisplaySpaceObject>
              </div>
            </div>
          )}
          {system.spaceObjectDisplaySetup === 1 && (
            <div>
              <div
                style={{
                  width: "45%",
                  height: "45%",
                  position: "absolute",
                  top: "2.5%",
                  left: "52.5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DisplaySpaceObject
                  spaceObject={system.objects[0]}
                  popup={popup}
                ></DisplaySpaceObject>
              </div>
              <div
                style={{
                  width: "45%",
                  height: "45%",
                  position: "absolute",
                  top: "52.5%",
                  left: "2.5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DisplaySpaceObject
                  spaceObject={system.objects[1]}
                  popup={popup}
                ></DisplaySpaceObject>
              </div>
            </div>
          )}
        </div>
        <SystemNameDisplay system={system}></SystemNameDisplay>
        <FleetDisplay system={system}></FleetDisplay>
        {mapDisplayMode === Popup.MAP_DISPLAY_FLEET && (
          <AllFleetDisplay system={system}></AllFleetDisplay>
        )}
        <div
          style={{
            position: "absolute",
            top: "60%",
            left: "50%",
            width: "100%",
            height: "100%",
            transform: `translate(-50%, -0%)`,
          }}
        ></div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.system === nextProps.system &&
      prevProps.mapDisplayMode === nextProps.mapDisplayMode
    );
  }
);

const SystemView = ({
  sizeX,
  sizeY,
  system,
  fontSizeP,
  mapDisplayMode = Popup.MAP_DISPLAY_MAP,
  showCombatIcon = false,
  popup,
  mapLightData,
}) => {
  const playerData = StaticGameData.getPlayerData();

  const [isBeingClicked, setIsBeingClicked] = useState(false);

  const isActiveSystem = system.isActiveSystem;

  let borderWith = "1px";
  let borderColor = Color.COLOR_SYSTEM_BORDER;
  /*if (system.faction) {
    borderColor = Color.getColorFromFaction(playerData, system.faction);
    borderWith = "4px";
  }*/

  let borderWidth = borderWith;
  let borderStyle = "solid";
  let borderConnectionLines = {
    borderWidth: borderWidth,
    borderStyle: borderStyle,
    borderColor: borderColor,
  };

  /*console.log("SystemView playerData : ", playerData);
  console.log("SystemView system : ", system);*/
  if (
    playerData.homeSystemCoords.x === system.x &&
    playerData.homeSystemCoords.y === system.y &&
    system.isFactionDraftSystem
  ) {
    borderConnectionLines = {
      borderWidth: "4px",
      borderStyle: borderStyle,
      borderColor: "gold",
    };
    //"2px solid rgba(255, 255, 0)";
  }
  if (isActiveSystem) {
    borderConnectionLines = {
      borderWidth: "4px",
      borderStyle: borderStyle,
      borderColor: "rgba(240, 240, 240)",
    };
  }

  //system = { object: { name: "Planet X" } };

  if (!system) {
    throw new Error("System data is null");
  }

  const style = {
    //border: "1px solid black",
    boxSizing: "border-box",
    width: sizeX + "px",
    height: sizeY + "px",
    userSelect: "none",
    position: "relative",
    //outline: borderConnectionLines,
    ...borderConnectionLines,
    borderStyle: system.faction ? "solid" : "dotted",
    pointerEvents: "none",
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    //console.log("SystemView clicked down : " + system.x + " " + system.y);
    setIsBeingClicked(true);
  };
  const handleMouseMove = (event) => {
    setIsBeingClicked(false);
  };

  const handleMouseUp = () => {
    if (isBeingClicked) {
      //console.log("SystemView clicked up : " + system.x + " " + system.y);

      setIsBeingClicked(false);

      //Open combat logs
      if (playerData.step === Phase.STEP_EORR) {
        if (
          EndRoundReviewCA.getStepEORR(playerData) === "space combat" &&
          system.endRoundData.didSpaceCombatOccur
        ) {
          Popup.addLayer({
            name: "FullScreenLogView",
            logBook: system.endRoundData.spaceCombatBook,
            navigationData: {
              navigationButtonsData: [
                {
                  text: "Back",
                  callback: () => {
                    Popup.goBack();
                  },
                },
              ],
            },
          });
          return;
        }
      }

      //Open manage system view
      Popup.addLayer({
        name: "ManageSystemView",
        system: system,
        navigationData: {
          navigationButtonsData: [
            {
              text: "Back",
              callback: () => {
                Popup.goBack();
              },
            },
          ],
        },
      });
    }
  };

  const handleMouseLeave = () => {
    setIsBeingClicked(false);
  };

  /*const DisplayHeavyStuff = useMemo(() => {
    //This stuff is independant to the Popup.touch
        let backgroundColor = "none";

    return <div style={{ position: "absolute", top: 0, left: 0 }}> </div>;
  }, []);*/

  return (
    <div
      className="div-fill"
      style={style}
      //onMouseDown={handleMouseDown}
      //onMouseUp={handleMouseUp}
      //onMouseMove={handleMouseMove}
      //onMouseLeave={handleMouseLeave}
      //onClick={handleMouseUp}
    >
      <SystemBox
        system={system}
        sizeX={sizeX}
        fontSizeP={fontSizeP}
        popup={popup}
        mapDisplayMode={mapDisplayMode}
      ></SystemBox>

      <InformationIcon
        popup={popup}
        shouldShowCombatIcon={Popup.getShowCombatIcon()}
        system={system}
      ></InformationIcon>
    </div>
  );
};

/*<div
            className="div-fill"
            style={{ position: "relative", height: "20%" }}
          >
            <ObjectText></ObjectText>
          </div>*/

export default SystemView;

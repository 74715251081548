import React, { useState } from "react";

import MinorFactionObjectView from "./MinorFactionObjectView";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import ObjectiveView from "./ObjectiveView";

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";

const IMAGE_SIZE_SQUARE = 25;
const IMAGE_SIZE_ICON_SQUARE = "10px";
const IMAGE_SIZE_OVAL_HEIGHT = 10;
const IMAGE_SIZE_OVAL_WIDTH = 30;
const IMAGE_SIZE_PORTRAIT = 28;

const FONT_SIZE = "8px";

const MinorFactionAndObjectiveMapView = ({
  spaceObject,
  size = IMAGE_SIZE_SQUARE,
}) => {
  //console.log("DEBUG MinorFactionObjectView spaceObject : ", spaceObject);

  return (
    <div className="div-hor-center">
      <MinorFactionObjectView
        spaceObject={spaceObject}
        mapView={true}
        size={IMAGE_SIZE_SQUARE}
      ></MinorFactionObjectView>
    </div>
  );
};

export default MinorFactionAndObjectiveMapView;

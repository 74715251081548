const LogBook = require("../Connection/LogBook.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Cost = require("../Utils/Cost.js");
const Color = require("../../../Common/Config/Colors.js");
const AbilityCommon = require("../Ability/AbilityCommon.js");
const UnitAbility = require("../Ability/UnitAbility.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");
const Item = require("../Transactions/Item.js");

class Unit {
  //Class
  static CLASS_SHIP = "ship";
  static CLASS_GROUND_FORCE = "ground force";
  static CLASS_STRUCTURE = "structure";
  static CLASS_STRUCTURE_ALL = "all";

  //Ships
  static UNIT_TYPE_DESTROYER = "Destroyer";
  static UNIT_TYPE_DREADNOUGH = "Dreadnought";
  static UNIT_TYPE_CRUSER = "Cruiser";
  static UNIT_TYPE_CARRIER = "Carrier";
  static UNIT_TYPE_FIGHTER = "Fighter";

  //Ground Forces
  static UNIT_TYPE_INFANTRY = "Infantry";
  static UNIT_TYPE_MECH = "Mech";

  //Structures
  static UNIT_TYPE_ASSEMBLY_ORBITAL = "Assembly Orbital";
  static UNIT_TYPE_SUPPLY_BASE = "Supply Base";
  static UNIT_TYPE_GENERATOR = "Generator";
  static UNIT_TYPE_CITY = "City";
  static UNIT_TYPE_RESEARCH_LAB = "Research Lab";
  static UNIT_TYPE_FACTORY = "Factory";
  static UNIT_TYPE_ORBITAL_CANON = "Orbital Canon";
  static UNIT_TYPE_PLANETARY_CANON = "Planetary Canon";
  static UNIT_TYPE_PLANETARY_SHIELD = "Planetary Shield";

  static generateUnitCreationId(playerData) {
    const inc = CustomMath.generateRandomNumber(1, 5);
    playerData.unitCreationId = playerData.unitCreationId + inc;
    return playerData.unitCreationId;
  }

  static createUnit(
    playerData,
    faction,
    type,
    subType = "",
    generateId = true
  ) {
    let id = null;
    if (generateId) {
      const idNumber = this.generateUnitCreationId(playerData);
      id = `${playerData.playerInGameId}-${idNumber}`;
    }

    const unit = {
      id: id,
      color: Color.getColorFromFaction(playerData, faction),
      abilities: [],
    };

    //console.log("Creating unit faction : ", faction);
    //console.log("Creating unit : ", unit);

    //Ships
    if (type === this.UNIT_TYPE_DREADNOUGH) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_BOMBARDMENT, {
          min: 1.5,
          max: 3.5,
        })
      );
      return {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        hp: 8,
        maxHp: 8,
        combatMin: 0.8,
        combatMax: 1.8,
        range: 1,
        class: "ship",
        morale: 4,
        capacity: 2,
        requiredCapacity: 0,
        costMineral: 4,
        costEnergy: 0,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_ASSEMBLY_ORBITAL],
      };
    }

    if (type === this.UNIT_TYPE_DESTROYER) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_AFC, {
          min: 0.7,
          max: 1.4,
        })
      );
      return {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        range: 2,
        hp: 6,
        maxHp: 6,
        combatMin: 0.2,
        combatMax: 1.1,
        class: "ship",
        morale: 2,
        capacity: 0,
        requiredCapacity: 0,
        costMineral: 1,
        costEnergy: 0,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    if (type === this.UNIT_TYPE_CRUSER) {
      return {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        range: 2,
        hp: 7,
        maxHp: 7,
        combatMin: 0.4,
        combatMax: 1.4,
        class: "ship",
        morale: 2,
        capacity: 0,
        requiredCapacity: 0,
        costMineral: 2,
        costEnergy: 0,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    if (type === this.UNIT_TYPE_CARRIER) {
      return {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        range: 1,
        hp: 7,
        maxHp: 7,
        combatMin: 0.2,
        combatMax: 1.1,
        class: "ship",
        morale: 3,
        capacity: 4,
        requiredCapacity: 0,
        costMineral: 3,
        costEnergy: 0,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    if (type === this.UNIT_TYPE_FIGHTER) {
      return {
        ...unit,
        type: type,
        subType: subType,
        mass: 0,
        range: null,
        hp: 4,
        maxHp: 4,
        combatMin: 0.2,
        combatMax: 1.1,
        class: "ship",
        morale: 0.5,
        capacity: 0,
        requiredCapacity: 1,
        transportRequired: true,
        costEnergy: 0,
        costMineral: 0.3,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    //Ground Forces
    if (type === this.UNIT_TYPE_INFANTRY) {
      return {
        ...unit,
        type: type,
        subType: subType,
        mass: 0,
        hp: 2,
        maxHp: 2,
        combatMin: 0.2,
        combatMax: 1.2,
        class: "ground force",
        morale: 1,
        costMineral: 0,
        costEnergy: 0,
        costPopulation: 0.6,
        transportRequired: true,
        requiredCapacity: 1,
        requiredStructures: [],
        //requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    if (type === this.UNIT_TYPE_MECH) {
      return {
        ...unit,
        type: type,
        subType: subType,
        mass: 0,
        hp: 5,
        maxHp: 5,
        combatMin: 0.4,
        combatMax: 1.4,
        class: "ground force",
        morale: 3,
        costMineral: 2,
        costEnergy: 0,
        costPopulation: 0,
        transportRequired: true,
        requiredCapacity: 1,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    //Structures
    if (type === this.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        morale: 1,
        costMineral: 2,
        costEnergy: 0,
        costPopulation: 3,
        onlyOnePerPlanet: true,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
        constructionAmount: 2,
        constructableUnits: [
          this.UNIT_TYPE_DREADNOUGH,
          this.UNIT_TYPE_CARRIER,
          this.UNIT_TYPE_FIGHTER,
        ],
        maxAmountOnPlanet: 1,
      };
    }

    if (type === this.UNIT_TYPE_PLANETARY_CANON) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_SPACE_CANON_DEFENSE, {
          min: 2.5,
          max: 4.5,
        })
      );
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(
          UnitAbility.ID_PLANETARY_CANON_DEFENSE,
          {
            min: 1.5,
            max: 2.5,
          }
        )
      );
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        morale: 1,
        costMineral: 0,
        costEnergy: 0,
        costPopulation: 3,
        onlyOnePerPlanet: true,
        requiredStructures: [],
        maxAmountOnPlanet: 2,
      };
    }

    if (type === this.UNIT_TYPE_PLANETARY_SHIELD) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_PLANETARY_SHIELD, {
          value: 50,
        })
      );
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        morale: 1,
        costMineral: 0,
        costEnergy: 1,
        costPopulation: 1,
        onlyOnePerPlanet: true,
        requiredStructures: [],
        maxAmountOnPlanet: 1,
      };
    }

    if (type === this.UNIT_TYPE_SUPPLY_BASE) {
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 0,
        costEnergy: 1,
        costPopulation: 1,
      };
    }
    if (type === this.UNIT_TYPE_GENERATOR) {
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 1,
        costEnergy: 0,
        costPopulation: 1.5,
      };
    }
    if (type === this.UNIT_TYPE_CITY) {
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 0,
        costEnergy: 0,
        costPopulation: 0,
        costInfluence: 5,
      };
    }
    if (type === this.UNIT_TYPE_RESEARCH_LAB) {
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 0,
        costEnergy: 3,
        costPopulation: 2,
      };
    }
    if (type === this.UNIT_TYPE_FACTORY) {
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        morale: 1,
        costMineral: 1,
        costEnergy: 0,
        costPopulation: 3,
        requiredStructures: [],
        constructionAmount: 2,
        constructableUnits: [
          this.UNIT_TYPE_CARRIER,
          this.UNIT_TYPE_CRUSER,
          this.UNIT_TYPE_FIGHTER,
          this.UNIT_TYPE_DESTROYER,
          this.UNIT_TYPE_INFANTRY,
          this.UNIT_TYPE_MECH,
        ],
        maxAmountOnPlanet: 1,
      };
    }
    if (type === this.UNIT_TYPE_ORBITAL_CANON) {
      return {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 0,
        costEnergy: 2,
        costPopulation: 1,
      };
    }

    throw new Error("Unit.createUnit : type not found");
  }

  static getUnitShipsCatalog(playerData, faction) {
    return [
      this.createUnit(playerData, faction, this.UNIT_TYPE_DREADNOUGH, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_DESTROYER, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_CRUSER, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_CARRIER, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_FIGHTER, ""),
    ];
  }

  static getUnitGroundForcesCatalog(playerData, faction) {
    return [
      this.createUnit(playerData, faction, this.UNIT_TYPE_INFANTRY, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_MECH, ""),
    ];
  }

  static getUnitStructuresCatalog(playerData, faction) {
    return [
      this.createUnit(playerData, faction, this.UNIT_TYPE_ASSEMBLY_ORBITAL, ""),
      //this.createUnit(playerData, faction, this.UNIT_TYPE_SUPPLY_BASE, ""),
      //this.createUnit(playerData, faction, this.UNIT_TYPE_GENERATOR, ""),
      //this.createUnit(playerData, faction, this.UNIT_TYPE_CITY, ""),
      //this.createUnit(playerData, faction, this.UNIT_TYPE_RESEARCH_LAB, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_FACTORY, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_PLANETARY_CANON, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_PLANETARY_SHIELD, ""),

      //this.createUnit(playerData, faction, this.UNIT_TYPE_ORBITAL_CANON, ""),
    ];
  }

  static getConstructionAmount(playerData, unit) {
    if (!unit.constructionAmount) return null;

    let value = unit.constructionAmount;

    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.quantumAssemblyLine.name)
    ) {
      if (
        unit.type === this.UNIT_TYPE_FACTORY ||
        unit.type === this.UNIT_TYPE_ASSEMBLY_ORBITAL
      ) {
        value += 1;
      }
    }

    return value;
  }

  static getCapacity(playerData, unit) {
    let modifier = 0;

    if (Item.playerHasExhaustedItem(playerData, Item.NAME_SELTAAR_SCROLL)) {
      if (
        unit.class === Unit.UNIT_TYPE_DREADNOUGH ||
        unit.class === Unit.UNIT_TYPE_CARRIER
      ) {
        modifier = modifier + 2;
      }
    }

    return unit.capacity + modifier;
  }

  static getRequiredCapacity(playerData, unit) {
    let modifier = 0;
    return unit.requiredCapacity;
  }

  static getConstructableUnitsFromUnit(playerData, faction, unit) {
    const catalog = [];

    if (
      !Unit.getConstructionAmount(playerData, unit) ||
      !unit.constructableUnits
    ) {
      return null;
    }

    for (let i = 0; i < unit.constructableUnits.length; i++) {
      catalog.push(
        this.createUnit(
          playerData,
          faction,
          unit.constructableUnits[i],
          "",
          false
        )
      );
    }

    return catalog;
  }

  static canUnitConstruct(playerData, unit) {
    if (
      Unit.getConstructionAmount(playerData, unit) &&
      unit.constructableUnits
    ) {
      return true;
    }
    return false;
  }

  static checkUnitConstructUnit(playerData, unit, unitToConstruct) {
    if (
      Unit.getConstructionAmount(playerData, unit) &&
      unit.constructableUnits
    ) {
      for (let i = 0; i < unit.constructableUnits.length; i++) {
        if (this.areUnitsTheSame(unit.constructableUnits[i], unitToConstruct)) {
          return true;
        }
      }
    }
    throw new Error(unit.name + " can't construct a " + unitToConstruct.name);
  }

  static checkUnitConstructUnitList(playerData, unit, unitList) {
    const constructionAmount = Unit.getConstructionAmount(playerData, unit);
    if (constructionAmount > unitList.length) {
      throw new Error(
        unit.name +
          " can only construct " +
          constructionAmount +
          " units. It is trying to construct " +
          unitList.length +
          " units."
      );
    }

    if (constructionAmount && unit.constructableUnits) {
      for (let i = 0; i < unitList.length; i++) {
        this.checkUnitConstructUnit(unit, unitList[i]);
      }
    }
  }

  //Get

  static getHP(playerData, unit) {
    //console.log("Unit.getHP : unit : ", unit);
    return unit.hp;
  }

  static doesUnitRollCombat(playerData, unit) {
    return unit.combatMin && unit.combatMax;
  }

  static getMinDamage(playerData, unit) {
    if (!unit.combatMin) {
      return null;
    }

    let modifier = 0;
    if (
      TechTree.hasPlayerTech(
        playerData,
        TechList.TECH.improvedShipTargetting.name
      ) &&
      unit.class === Unit.CLASS_SHIP
    ) {
      modifier = modifier + 0.1;
    }
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.improvedWeaponry.name) &&
      unit.class === Unit.CLASS_GROUND_FORCE
    ) {
      modifier = modifier + 0.1;
    }

    //Calvaria scroll
    if (Item.playerHasExhaustedItem(playerData, Item.NAME_CALVARIA_SCROLL)) {
      modifier = modifier + 0.2;
    }

    return CustomMath.roundDec(unit.combatMin + modifier);
  }

  static setMinDamage(playerData, unit, minDamage) {}

  static getMaxDamage(playerData, unit) {
    if (!unit.combatMax) {
      return null;
    }

    let modifier = 0;
    if (
      TechTree.hasPlayerTech(
        playerData,
        TechList.TECH.improvedShipTargetting.name
      ) &&
      unit.class === Unit.CLASS_SHIP
    ) {
      modifier = modifier + 0.1;
    }
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.improvedWeaponry.name) &&
      unit.class === Unit.CLASS_GROUND_FORCE
    ) {
      modifier = modifier + 0.1;
    }

    //Calvaria scroll
    if (Item.playerHasExhaustedItem(playerData, Item.NAME_CALVARIA_SCROLL)) {
      modifier = modifier + 0.2;
    }

    return CustomMath.roundDec(unit.combatMax + modifier);
  }

  static setMaxDamage(playerData, unit, maxDamage) {}

  static setHP(playerData, unit, hp) {
    unit.hp = hp;
  }

  static getMaxHP(playerData, unit) {
    let modifier = 0;
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.reinforcedHull.name) &&
      unit.class === Unit.CLASS_SHIP
    ) {
      modifier = modifier + 1;
    }
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.reinforcedArmor.name) &&
      unit.class === Unit.CLASS_GROUND_FORCE
    ) {
      modifier = modifier + 1;
    }

    return unit.maxHp + modifier;
  }

  static getRange(playerData, unit) {
    return unit.range;
  }

  static setMaxHP(playerData, unit, maxHp) {
    unit.maxHp = maxHp;
  }

  static getMaxAmountOnPlanet(playerData, unit) {
    if (unit.maxAmountOnPlanet) {
      return unit.maxAmountOnPlanet;
    }
    return null;
  }

  static getTypeAbbreviation(type) {
    if (type === this.UNIT_TYPE_DREADNOUGH) {
      return "Dread";
    }

    if (type === this.UNIT_TYPE_DESTROYER) {
      return "Dest";
    }

    if (type === this.UNIT_TYPE_CRUSER) {
      return "Cru";
    }

    if (type === this.UNIT_TYPE_CARRIER) {
      return "Car";
    }

    if (type === this.UNIT_TYPE_FIGHTER) {
      return "Fight";
    }

    if (type === this.UNIT_TYPE_INFANTRY) {
      return "Inf";
    }

    if (type === this.UNIT_TYPE_MECH) {
      return "Mech";
    }

    if (type === this.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      return "AssOrb";
    }

    if (type === this.UNIT_TYPE_SUPPLY_BASE) {
      return "SupBase";
    }

    if (type === this.UNIT_TYPE_GENERATOR) {
      return "Gen";
    }

    if (type === this.UNIT_TYPE_CITY) {
      return "City";
    }

    if (type === this.UNIT_TYPE_RESEARCH_LAB) {
      return "ResLab";
    }

    if (type === this.UNIT_TYPE_FACTORY) {
      return "Fact";
    }

    if (type === this.UNIT_TYPE_ORBITAL_CANON) {
      return "OrbCan";
    }

    throw new Error("Unit.getTypeAbbreviation : type not found");
  }

  static getSuperShortAbbreviation(type) {
    if (type === this.UNIT_TYPE_DREADNOUGH) {
      return "dr";
    }

    if (type === this.UNIT_TYPE_DESTROYER) {
      return "de";
    }

    if (type === this.UNIT_TYPE_CRUSER) {
      return "cu";
    }

    if (type === this.UNIT_TYPE_CARRIER) {
      return "ca";
    }

    if (type === this.UNIT_TYPE_FIGHTER) {
      return "ff";
    }

    if (type === this.UNIT_TYPE_INFANTRY) {
      return "if";
    }

    if (type === this.UNIT_TYPE_MECH) {
      return "me";
    }

    if (type === this.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      return "ao";
    }

    if (type === this.UNIT_TYPE_SUPPLY_BASE) {
      return "sb";
    }

    if (type === this.UNIT_TYPE_GENERATOR) {
      return "gn";
    }

    if (type === this.UNIT_TYPE_CITY) {
      return "ct";
    }

    if (type === this.UNIT_TYPE_RESEARCH_LAB) {
      return "rl";
    }

    if (type === this.UNIT_TYPE_FACTORY) {
      return "fa";
    }

    /*if (type === this.UNIT_TYPE_ORBITAL_CANON) {
      return "oc";
    }*/

    if (type === this.UNIT_TYPE_PLANETARY_CANON) {
      return "pla_can";
    }

    if (type === this.UNIT_TYPE_PLANETARY_SHIELD) {
      return "pla_shield";
    }

    throw new Error("Unit.getTypeAbbreviation : type not found");
  }

  static getSubtypeAbbreviation(subType) {
    return "";
  }

  static getDisplaySize(type) {
    if (type === this.UNIT_TYPE_DREADNOUGH) {
      return "100%";
    }

    if (type === this.UNIT_TYPE_DESTROYER) {
      return "70%";
    }

    if (type === this.UNIT_TYPE_CRUSER) {
      return "80%";
    }

    if (type === this.UNIT_TYPE_CARRIER) {
      return "90%";
    }

    if (type === this.UNIT_TYPE_FIGHTER) {
      return "50%";
    }

    if (type === this.UNIT_TYPE_INFANTRY) {
      return "40%";
    }

    if (type === this.UNIT_TYPE_MECH) {
      return "70%";
    }

    if (type === this.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      return "100%";
    }

    if (type === this.UNIT_TYPE_SUPPLY_BASE) {
      return "90%";
    }

    if (type === this.UNIT_TYPE_GENERATOR) {
      return "90";
    }

    if (type === this.UNIT_TYPE_CITY) {
      return "90";
    }

    if (type === this.UNIT_TYPE_RESEARCH_LAB) {
      return "90";
    }

    if (type === this.UNIT_TYPE_FACTORY) {
      return "90";
    }

    if (type === this.UNIT_TYPE_ORBITAL_CANON) {
      return "90";
    }

    throw new Error("Unit.getTypeAbbreviation : type not found");
  }

  static findUnitInArray(unit, unitsArray) {
    for (let i = 0; i < unitsArray.length; i++) {
      if (this.areUnitsTheSame(unitsArray[i], unit)) return unitsArray[i];
    }
    return null;
  }

  static areUnitsTheSame(unitA, unitB) {
    if (!unitA.id && !unitB.id) {
      if (unitA.type === unitB.type && unitA.subType === unitB.subType) {
        return true;
      } else {
        return false;
      }
    } else {
      if (unitA.id === unitB.id) {
        return true;
      } else {
        return false;
      }
    }
  }

  static getPrintingName(unit) {
    return `${unit.type} ${unit.subType}`;
  }

  static getPrintingText(unit) {
    return `${unit.type} ${unit.subType} ${Unit.getHP(unit)}/${Unit.getMaxHP(
      unit
    )}`;
  }

  static getLogItem(unit) {
    return { type: "unit", content: this.getPrintingText(unit) };
  }

  static getCost(playerData, unit) {
    return Cost.createCost({
      mineral: unit.costMineral,
      energy: unit.costEnergy,
      population: unit.costPopulation,
      influence: unit.costInfluence,
      cargo: unit.costCargo,
      credit: unit.costCredit,
      science: unit.costScience,
    });
  }

  static repair(playerData, unit, amount, logBook = null) {
    const previousHP = Unit.getHP(unit);
    const effectiveAmount = CustomMath.roundInt(
      Math.min(
        Unit.getMaxHP(playerData, unit) - Unit.getHP(playerData, unit),
        amount
      )
    );

    if (logBook) {
      LogBook.createMessage(logBook, [
        { type: "unit", content: unit },
        { type: "text", content: " is repaired by " + amount },
      ]);
    }

    Unit.setHP(
      playerData,
      unit,
      CustomMath.roundInt(Unit.getHP(unit) + effectiveAmount)
    );
  }

  static createRandomShip(playerData, faction) {
    const ships = this.getUnitShipsCatalog(playerData, faction);
    return Unit.createUnit(
      playerData,
      faction,
      ships[CustomMath.generateRandomNumber(0, ships.length - 1)].type,
      "",
      true
    );
  }

  static createRandomGroundForce(playerData, faction) {
    const groundForces = this.getUnitGroundForcesCatalog(playerData, faction);
    return Unit.createUnit(
      playerData,
      faction,
      groundForces[CustomMath.generateRandomNumber(0, groundForces.length - 1)]
        .type,
      "",
      true
    );
  }

  static createRandomStructure(playerData, faction) {
    const structures = this.getUnitStructuresCatalog(playerData, faction);
    return Unit.createUnit(
      playerData,
      faction,
      structures[CustomMath.generateRandomNumber(0, structures.length - 1)]
        .type,
      "",
      true
    );
  }

  static shouldUnitBeTransported(playerData, unit) {
    return unit.transportRequired;
  }

  //Ability

  static getAbilities(playerData, unit) {
    const resultAbilities = [...unit.abilities];

    if (
      TechTree.hasPlayerTech(
        playerData,
        TechList.TECH.bombardmentWeaponsMiniaturization.name
      )
    ) {
      if (
        unit.class === Unit.CLASS_SHIP &&
        unit.type !== Unit.UNIT_TYPE_FIGHTER
      ) {
        resultAbilities.push(
          AbilityCommon.createAbilityData(UnitAbility.ID_BOMBARDMENT, {
            min: 0.5,
            max: 1.5,
          })
        );
      }
    }

    return resultAbilities;
  }

  static getAbilitiesDescription(playerData, unit) {
    const abilities = this.getAbilities(playerData, unit);
    const abilityDescList = [];
    for (let i = 0; i < abilities.length; i++) {
      abilityDescList.push(
        UnitAbility.object[abilities[i].id](abilities[i].data)
      );
    }
    return abilityDescList;
  }

  static addAbility(unit, abilityData) {
    unit.abilities.push(abilityData);
  }

  static removeAbility(unit, abilityData) {
    // Find the index of the ability to be removed
    const index = unit.abilities.findIndex(
      (abilityDataUnit) => abilityDataUnit.id === abilityData.id
    );

    // If the ability was found, remove it
    if (index !== -1) {
      unit.abilities.splice(index, 1);
    }
  }

  static removeAllAbilities(unit) {
    unit.abilities = [];
  }

  static hasAbility(playerData, unit, abilityId) {
    const abilities = this.getAbilities(playerData, unit);
    for (let i = 0; i < abilities.length; i++) {
      if (abilities[i].id === abilityId) {
        return true;
      }
    }
    return false;
  }

  static getUnitWithAbility(playerData, unit, abilityId) {
    const abilities = this.getAbilities(playerData, unit);
    const abilitiesData = [];
    for (let i = 0; i < abilities.length; i++) {
      if (abilities[i].id === abilityId) {
        abilitiesData.push(abilities[i].data);
      }
    }
    if (abilitiesData.length > 0) {
      return { unit: unit, data: abilitiesData };
    }
    return null;
  }

  static executeUnitAbility(playerData, unit, abilityId, callback) {
    const abilities = this.getAbilities(playerData, unit);
    for (let i = 0; i < abilities.length; i++) {
      if (abilities[i].id === abilityId) {
        callback(unit, abilities[i].data);
      }
    }
  }
}

module.exports = Unit;
